//Colors
$white: #ffffff;
$black: #000000;
$green: #2dc093;
$jumna-blue: #1a7cfa;
$home-sub-text: #232F3E;
$home-sub-opacity: rgba(35, 47, 62, 0.96);

//Font Family
$notosansRegular: "Notosans-regular";
$notosansBold: "NotoSans-Bold";
$plusJakartaSans-Light : "PlusJakartaSans-Light";
$plusJakartaSans-Medium : "PlusJakartaSans-Medium";
$plusJakartaSans-Regular : "PlusJakartaSans-Regular";
$plusJakartaSans-SemiBold : "PlusJakartaSans-SemiBold";
$plusJakartaSans-Bold : "PlusJakartaSans-Bold";

@font-face {
    font-family: "Righteous";
    src: local("Righteous"),
        url(./fonts/Righteous-Regular.ttf) format("truetype");
    font-style: normal;
    font-weight: 400;
    font-display: auto;
}
@font-face {
    font-family: "Notosans-regular";
    src: local("Noto_Sans"),
        url(./fonts/Noto_Sans/NotoSans-Regular.ttf) format("truetype");
    font-style: normal;
    font-weight: 400;
    font-display: auto;
}
@font-face {
    font-family: "NotoSans-Bold";
    src: local("NotoSans-Bold"),
        url(./fonts/Noto_Sans/NotoSans-Bold.ttf) format("truetype");
    font-style: normal;
    font-weight: bold;
    font-display: auto;
}
@font-face {
    font-family: "PlusJakartaSans-Regular";
    src: local("PlusJakartaSans-Regular"),
        url(./fonts/Plus_Jakarta_Sans/PlusJakartaSans-Regular.ttf)
            format("truetype");
    font-style: normal;
    font-weight: 400;
    font-display: auto;
}
@font-face {
    font-family: "PlusJakartaSans-Medium";
    src: local("PlusJakartaSans-Medium"),
        url(./fonts/Plus_Jakarta_Sans/PlusJakartaSans-Medium.ttf)
            format("truetype");
    font-style: normal;
    font-weight: 500;
    font-display: auto;
}
@font-face {
    font-family: "PlusJakartaSans-Bold";
    src: local("PlusJakartaSans-Bold"),
        url(./fonts/Plus_Jakarta_Sans/PlusJakartaSans-Bold.ttf)
            format("truetype");
    font-style: normal;
    font-weight: 700;
    font-display: auto;
}
@font-face {
    font-family: "PlusJakartaSans-Light";
    src: local("PlusJakartaSans-Light"),
        url(./fonts/Plus_Jakarta_Sans/PlusJakartaSans-ExtraLight.ttf)
            format("truetype");
    font-style: normal;
    font-weight: 300;
    font-display: auto;
}
@font-face {
    font-family: "PlusJakartaSans-SemiBold";
    src: local("PlusJakartaSans-Light"),
        url(./fonts/Plus_Jakarta_Sans/PlusJakartaSans-SemiBold.ttf)
            format("truetype");
    font-style: normal;
    font-weight: 600;
    font-display: auto;
}
// .font-jakarta {
//     font-family: "PlusJakartaSans-Regular" !important;
// }
// html, body {
//     max-width: 100%;
//     overflow-x: hidden;
// }

body,
div,
p,
select,
button,
input,
table,
a,
span {
    font-family: $plusJakartaSans-Regular;
}
.MuiAppBar-positionFixed {
    left: 20;
    right: 20;
}

.fontBold {
    font-family: $plusJakartaSans-Bold;
}
.cursor {
    cursor: pointer;
}
.textCenter {
    text-align: center;
}
.alignItem_center {
    align-items: center;
}
.pendingBalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.pendingStatementLink {
    font-size: 14px;
    font-family: $plusJakartaSans-SemiBold;
    color: $jumna-blue;
    letter-spacing: 0.5px;
    margin-left: 16px;
}
.totalPendingBalance {
    font-family: $plusJakartaSans-Bold;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.5px;
    color: $jumna-blue;
}
.pendingBalanceText {
    font-family: $plusJakartaSans-Bold;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.5px;
    color: $black;
}
.paddingTopTwenty {
    padding-top: 20px;
}
.horizontalLine {
    width: 96%;
    border: 0.5px solid #d2d2d2;
    opacity: 0.5;
}
.boardingImg {
    padding: 0px 40px;
}
.headerText {
    font-family: $plusJakartaSans-Bold;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.855294px;
    color: $black;
    margin-bottom: 20px;
}
.forHeaderText {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 92%;
    margin: 0px auto 15px;
    white-space: nowrap;
    .MuiTypography-body1 {
        font-family: $plusJakartaSans-Bold;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: 1.35529px;
        color: $black;
    }
}
.storeAddress {
    .MuiFormLabel-root {
        font-size: 14px;
        font-family: $plusJakartaSans-Regular;
    }
}
.storeRadioGroup {
    .MuiTypography-body1 {
        font-family: $plusJakartaSans-Regular;
    }
}
.fileUploadImg {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0rem 1rem;
}
.uploadSec {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    gap: 5rem;
}
.transactionTable {
    padding: 30px;
}
.noTransText {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40;
    font-size: 26px;
    line-height: 35px;
    letter-spacing: 0.7px;
    color: #242F3E; 
    opacity: 0.8;
    font-family: $plusJakartaSans-Light;
    padding: 40px;
}
.imageItemBar {
    .MuiImageListItem-item {
        border-radius: 5px;
    }
    .MuiImageListItemBar-titleWrap {
        margin-left: 8px !important;
        margin-top: 72px !important;
    }
    .MuiImageListItemBar-rootSubtitle {
        //height: 42px !important;
        height: 122px !important;
    }
    .MuiImageListItemBar-root {
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 29.25%,
            rgba(0, 0, 0, 0.79) 100.11%
        );
    }
    .MuiImageListItemBar-subtitle {
        white-space: unset;
        padding: 4px 0px;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: -0.144828px;
        color: $white;
        mix-blend-mode: normal;
        text-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
        span {
            font-family: $plusJakartaSans-SemiBold;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
}
//manage storefront screen styles
.storeLinkSec {
    .MuiTypography-body1 {
        font-size: 14px;
        line-height: 18px;
        color: $green;
        font-family: $plusJakartaSans-SemiBold;
    }
    .MuiButton-label {
        font-family: $plusJakartaSans-SemiBold;
    }
}
.storeUnLinkSec {
    .MuiTypography-body1 {
        font-size: 14px;
        line-height: 18px;
        color: #B9B9B9;
        font-family: $plusJakartaSans-SemiBold;
    }
    .MuiButton-label {
        font-family: $plusJakartaSans-SemiBold;
    }
}
.fieldsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 100px;
}
//store pop up screens
.storePopUps {
    .MuiDialog-paperWidthSm {
        width: 100%;
        max-width: 500px;
    }
}
.modalTitle {
    .MuiTypography-h6 {
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        font-family: $plusJakartaSans-Bold;
    }
}
.actionBtn {
    .MuiButton-root {
        border-radius: 2px;
        line-height: 1.2;
    }
    .MuiButton-contained {
        background: #EEEEEE;
        box-shadow: none;
        color: #282828;
    }
    .MuiButton-label {
        font-weight: 600;
        font-size: 12px;
        text-align: center;
        letter-spacing: 0.7px;
        color: #282828;
        text-transform: none;
    }
    .MuiButton-outlined {
        border: 1px solid rgba(36,47,62,0.5);
        padding: 6px 24px;
    }
}
.editBtn {
    border: 1px solid rgba(36,47,62,0.5);
    padding: 6px 12px;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.7px;
    color: #282828;
    border-radius: 2px;
    line-height: 1.2;
    cursor: pointer;
    margin-top: -10px;
    margin-bottom: -10px;
}
.deleteBtn {
    background: #EEEEEE;
    padding: 8px 10px;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.7px;
    color: #282828;
    border-radius: 2px;
    line-height: 1.2;
    cursor: pointer;
    margin-top: -10px;
    margin-bottom: -10px;
}
.loyalty_table_btn_mob {
    display: flex;
    justify-content: space-between;
}
.hideBtn {
    display: none;
}
.discountText {
    position: absolute;
    bottom : 0;
    top: 134px;
    color: $white;
    padding: 5px 10px;
}
.radioWrapper {
    width: 60%;
    margin-top: 10px;
    .MuiFormControlLabel-root {
        &:nth-last-child(1) {
            margin-left: 30px;
            margin-right: auto;
        }
    }
}
.toggleSwitchDisable {
    .MuiSwitch-thumb {
        width: 9px;
        height: 9px;
        margin-top: 5.5px;
        margin-left: 5px;
        background-color: white;
    }
    .MuiSwitch-root {
        width: 50px;
        //width: 58px;
    }
}
.toggleSwitchEnable {
    .MuiSwitch-thumb {
        width: 9px;
        height: 9px;
        margin-top: 5.5px;
        margin-left: -2px;
        background-color: white;
    }
    .MuiSwitch-root {
        width: 50px;
        //width: 58px;
    }
    /* styles for active switch*/
    .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
        background-color: #2dc093;
    }
    .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
        opacity: 1;
    }
}
.switchDisable {
    .MuiSwitch-sizeSmall {
        width: 32px;
        height: 26px;
    }
    .MuiSwitch-sizeSmall .MuiSwitch-thumb {
        width: 7px;
        height: 7px;
        margin-top: 5.5px;
        margin-left: 5px !important;
        background-color: $white;
    }
}
/* styles for active switch*/
.switchEnable {
    .MuiSwitch-sizeSmall {
        width: 32px;
        height: 26px;
    }
    .MuiSwitch-sizeSmall .MuiSwitch-thumb {
        width: 7px;
        height: 7px;
        margin-top: 5.5px;
        margin-left: -4px;
        background-color: $white;
    }
    .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
        opacity: 1;
    }
    .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
        background-color: $green;
    }
}
.uploadButton {
    height: 60px !important;
    .filepond--drop-label label {
        white-space: nowrap !important;
        position: relative !important;
        right: 70px !important;
        background: #1a7cfa;
        box-shadow: 0px 2px 4px rgb(0 0 0 / 6%);
        border-radius: 2px;
        color: white;
        padding: 8px 16px !important;
        font-size: 16px;
    }
    .filepond--credits {
        display: none !important;
    }
}
.uploadstoreButton {
    height: 60px !important;
    cursor: pointer;
    .filepond--drop-label label {
        white-space: nowrap !important;
        position: relative !important;
        right: 55px !important;
        background: #1a7cfa;
        box-shadow: 0px 2px 4px rgb(0 0 0 / 6%);
        border-radius: 2px;
        color: white;
        padding: 8px 16px !important;
        font-size: 14px;
    }
    .filepond--credits {
        display: none !important;
    }
}
.fileUploadWrapper {
    .MuiButton-outlined {
        border: 1px solid $jumna-blue;
        padding: 6px 25px;
    }
}
.textCapitalize {
    text-transform: capitalize;
}
.dragAndDropSec {
    flex-grow: 1;
    flex-basis: 0;
    .filepond--root {
        box-sizing: border-box;
        position: relative;
        top: 4px;
        margin-bottom: 0px !important;
    }
    .filepond--drop-label {
        background-color: #f8f8f8;
    }
    .filepond--credits {
        display: none !important;
    }
    .filepond--drop-label.filepond--drop-label label {
        flex: 1;
        padding: 0;
    }
    .filepond--root :not(text) {
        font-size: 14px;
    }
}
.dragWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 6px;
    cursor: pointer;
}
.dragTxt {
    font-size: 14px;
    font-weight: 400;
    color: #282828;
    opacity: 0.5;
    font-family: $plusJakartaSans-Regular;
}
.dragButton {
    background: #1a7cfa;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 6%);
    border-radius: 2px;
    color: white;
    padding: 12px 20px;
    font-size: 14px;
    cursor: pointer;
}
.dragWrapperDiscount {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    cursor: pointer;
}
.dragButtonDiscount {
    background: #1a7cfa;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 6%);
    border-radius: 2px;
    color: white;
    padding: 8px 12px;
    font-size: 14px;
    cursor: pointer;
}
.cloudImg {
    width: 30px;
}
.fileUploadImgStore {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 8px;
}
.fileUploadSec {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.fileUploadWrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    background: #f8f8f8;
    padding: 0px 16px;
    justify-content: flex-start;
    gap: 1rem;
    border-radius: 7px;
    //border: "0.5px dashed #626262",
    background-image: repeating-linear-gradient(
            0deg,
            #626262,
            #626262 19px,
            transparent 19px,
            transparent 28px,
            #626262 28px
        ),
        repeating-linear-gradient(
            90deg,
            #626262,
            #626262 19px,
            transparent 19px,
            transparent 28px,
            #626262 28px
        ),
        repeating-linear-gradient(
            180deg,
            #626262,
            #626262 19px,
            transparent 19px,
            transparent 28px,
            #626262 28px
        ),
        repeating-linear-gradient(
            270deg,
            #626262,
            #626262 19px,
            transparent 19px,
            transparent 28px,
            #626262 28px
        );
    background-size: 0.8px 100%, 100% 0.8px, 0.8px 100%, 100% 0.8px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
}
.heightUpload {
    height: 77px;
}
.image_preview_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F8F8F8;
    padding: 10px 20px;
    gap: 1rem;
    box-sizing: border-box;
    cursor: pointer;
    width: 140px;
    height: 81px;
    margin-left: 20px;
}
.changeSec {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5rem;
    flex-grow: 1;
}
.rewardsTable {
    .MuiTableCell-head {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #0E0E0E;
        font-family: $plusJakartaSans-Bold;
    }
}
.loyaltyPopUp {
    .MuiDialog-paper {
        padding: 20px;
    }
    .MuiButton-label {
        text-transform: none;
    }
    .MuiDialogActions-root {
        justify-content: center;
    }
    .MuiDialogTitle-root {
        padding: 0px 24px 20px;
    }
}
.promoTxt {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
        margin-right: 10px;
    }
    p{
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.5px;
        color: #242F3E;
    }
}
.promoSec {
    background: #F3ECE7;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    padding: 8px 16px;
    img {
        margin-right: 10px;
    }
    p{
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.5px;
        color: #333F48;
    }
}
.promoBtn {
    .MuiButton-containedPrimary {
        background: #1A7CFA;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0605059);
        border-radius: 2px;
        padding: 10px;
        font-size: 16px;
        text-align: center;
        letter-spacing: 0.8px;
        color: #FFFFFF;
        font-family: $plusJakartaSans-Regular;
        font-weight: 700;
        width: 150px;
    }
    .MuiButton-outlinedPrimary {
        background: $white;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0605059);
        color: $jumna-blue;
        border-radius: 2px;
        padding: 10px;
        font-size: 16px;
        text-align: center;
        letter-spacing: 0.8px;
        font-family: $plusJakartaSans-Regular;
        font-weight: 700;
        width: 150px;
    }
}
.creditContainer {
    width: 90%;
    margin: 20px auto;
    .MuiPaper-elevation1 {
        box-shadow: none;
    }
}
.tableWidth {
    //height: 60vh;
    .MuiTableCell-root {
        min-width: 100px;
        padding: 16px 10px;
        font-size: 13px;
        font-family: $plusJakartaSans-Regular;
        letter-spacing: 0.5px;
        &:first-child{
            text-align: center;
        }
    }
    .MuiTableHead-root {
        background-color: #F4F9FF;
        border-top: 0.5px solid #d2d2d2;
    }

    .statementTableRow {
        .MuiTableCell-root {
            min-width: 100px;
            padding: 16px 10px;
            font-size: 13px;
            font-family: $plusJakartaSans-Regular;
            letter-spacing: 0.5px;
            &:first-child{
                text-align: start;
                padding-left: 30px;
            }
        }
    }
}
.textBold {
    .MuiTableCell-head {
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.5px;
      color: #0E0E0E;
      font-family: $plusJakartaSans-Bold;
    }
  }
  .textNormal {
      .MuiTableCell-root {
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.5px;
        color: #0E0E0E;
        font-family: $plusJakartaSans-Regular;
      }
  }
.search-field {
    .MuiInputLabel-outlined.MuiInputLabel-marginDense {
        margin-left: 25px !important;
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        margin-left: 2px !important;
    }
    .MuiOutlinedInput-inputMarginDense {
        margin-left: 25px !important;
    }
}
.regAdressSec {
    display: flex;
    flex-wrap: wrap;
    .MuiInputBase-input.Mui-disabled {
        opacity: 0.5;
    }
}
.registerFormTxt {
    .MuiInputBase-root {
        font-size: 14px;
        letter-spacing: 0.7px;
        color: #282828;
        font-family: $plusJakartaSans-Regular;
    }
    .MuiFormLabel-root {
        font-size: 14px;
        letter-spacing: 0.7px;
        color: #282828;
        mix-blend-mode: normal;
        opacity: 0.5;
        font-family: $plusJakartaSans-Regular;
    }
    .MuiTypography-body1 {
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 0.7px;
        color: $jumna-blue;
        font-family: $plusJakartaSans-Regular;
        text-align: right;
        margin-top: 10px;
    }
    .MuiInputBase-input {
        font-size: 18px;
        color: #282828;
    }
    .MuiFormLabel-root.Mui-disabled {
        color: #000000;
        opacity: 0.5;
    }
}
#menu-category {
    .MuiMenu-paper {
        max-height: 300px !important;
        width: 265px !important;
        font-size: 15px !important;
        //position: relative !important;
    }
}
.forgotPswdSec {
    .MuiTypography-body1 {
        font-weight: 600;
        font-size: 20px;
        color: #242f3e;
        line-height: 27px;
        letter-spacing: 0.7px;
        margin-bottom: 20px;
    }
    .MuiFormLabel-root {
        font-size: 14px;
        letter-spacing: 0.7px;
        color: #282828;
        mix-blend-mode: normal;
        opacity: 0.5;
        font-family: $plusJakartaSans-Regular;
    }
    .MuiInputBase-input {
        font-size: 18px;
        color: #282828;
    }
}
.forTxtInput {
    .MuiInputBase-root {
        font-size: 14px;
        letter-spacing: 0.7px;
        color: #282828;
        font-family: $plusJakartaSans-Regular;
    }
    .MuiFormLabel-root {
        font-size: 14px;
        letter-spacing: 0.7px;
        color: #282828;
        mix-blend-mode: normal;
        opacity: 0.5;
        font-family: $plusJakartaSans-Regular;
    }
    .MuiInputBase-input {
        font-size: 18px;
        color: #282828;
    }
}
.reCapchaSec {
    margin: 32px auto 0px;
    display: flex;
    justify-content: center;
}
.reCapchaSecDemo {
    margin: 32px auto 0px;
    display: flex;
    justify-content: center;
}
.reCapchaNewSignup {
    display: flex;
    justify-content: center;
    margin: 30px 20px 10px;
}
.reCapchaJoin {
    display: flex;
    justify-content: center;
    margin: 10px 30px 0px;
}
.reCapchaHome {
    margin-left: 40px;
}
.descriptionSec {
    .MuiFormControl-root {
        width: 100% !important;
    }
}
.passwordResetSec {
    width: 100%;
    max-width: 400px;
    margin: 0px auto;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .MuiFormLabel-root {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.7px;
        color: #282828;
        opacity: 0.5;
        font-family: $plusJakartaSans-Medium;
    }
    .MuiButton-label {
        text-transform: none;
    }
    .MuiFormHelperText-root.Mui-error {
        margin-top: -16px;
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before,
    .MuiInput-underline:after {
        border-bottom: 2px solid $jumna-blue;
    }
}
.linkedAccountSec {
    width: 100%;
    max-width: 400px;
    margin: 0px auto;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.accountTextWrapper {
    background: rgba(187, 29, 29, 0.03);
    display: flex;
    padding: 0px 40px;
    align-items: center;
    height: 47px;
    width: 70%;
    justify-content: center;
    max-width: 500px;
    margin: 30px auto;
}
.linkedAccountMain {
    padding: 30px;
    box-sizing: border-box;
}
// multiple bank accounts
.linkedMultiAccountMain {
    padding: 40px 60px;
    box-sizing: border-box;
}
.linkedAccountHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .MuiButton-label {
        text-transform: none;
        font-family: $plusJakartaSans-SemiBold;
    }
    .MuiTypography-body1 {
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.5px;
        color: $black;
        font-family: $plusJakartaSans-Bold;
    }
    .MuiButton-containedPrimary {
        background: #1A7CFA;
        border-radius: 2px;
        font-size: 14px;
        text-align: center;
        letter-spacing: 0.8px;
        color: #FFFFFF;
        cursor: pointer;
    }
    .MuiButton-containedPrimary:hover {
        background-color: $jumna-blue;
    }
    .MuiButton-outlinedPrimary {
        background: $white;
        border: 1px solid $jumna-blue;
        color: $jumna-blue;
        border-radius: 2px;
        font-size: 14px;
        text-align: center;
        letter-spacing: 0.8px;
        cursor: pointer;
    }
    .MuiButton-outlinedPrimary:hover {
        border: 1px solid $jumna-blue;
        background-color: $white;
    }
}
.multiAccountTable {
    .MuiTableCell-root {
        min-width: 100px;
        padding: 16px 10px;
        font-size: 14px;
        font-family: $plusJakartaSans-Bold;
        letter-spacing: 0.5px;
        color: rgba(40,40,40,0.8);
    }
    .MuiTableHead-root {
        background-color: #F4F9FF;
        border-top: 0.5px solid #d2d2d2;
    }
}
.linkedAccountWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.primaryTxt {
    .MuiTypography-body1,
    .MuiTableCell-body {
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.9px;
        color: #242F3E;
        font-family: $plusJakartaSans-SemiBold;
    }
}
.setAsPrimaryTxt {
    .MuiTableCell-body {
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.9px;
        color: #282828;
        font-family: $plusJakartaSans-Regular;
    }
    .MuiButton-root {
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.9px;
        color: #1A7CFA;
        text-transform: none;
    }
    .MuiButton-text {
        padding: 6px 0px;
    }
    .MuiButton-label {
        font-family: $plusJakartaSans-Bold;
    }
}
.uploadDocTableTxt {
    .MuiTableCell-body {
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.5px;
        color: #242F3E;
        font-family: $plusJakartaSans-Regular;
    }
}
.accountSecBank {
    width: 400px;
    height: 300px;
    background-color: #F4F9FF;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .MuiButton-containedPrimary {
        background-color: $jumna-blue;
    }
    .MuiButton-containedPrimary:hover {
        background-color: $jumna-blue;
    }
    .MuiButton-contained {
        box-shadow: none;
    }
    .MuiButton-root {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.8px;
        color: #FFFFFF;
        //opacity: 0.9;
        text-transform: none;
    }
    .MuiButton-label {
        font-family: $plusJakartaSans-SemiBold !important;
    }
}
.bankImg {
    width: 110px;
    height: 110px;
    margin-bottom: 30px;
}
.linkBankBtn {
    width: 250px;
    height: 48px;
}
.accountDetailsSec {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    .MuiButton-outlined {
        //opacity: 0.7;
        border: 1px solid #242F3E;
        border-radius: 2px;
        padding: 5px;
    }
    .MuiButton-root {
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.7px;
        color: #282828;
        text-transform: none;
    }
    .MuiButton-label {
        font-family: $plusJakartaSans-SemiBold !important;
    }
}
.bankDetailsWrapper {
    padding: 20px 60px;
    display: flex;
    flex-direction: column;
}
.bankHeadTxt {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.6px;
    color: #282828;
    opacity: 0.8;
    margin-bottom: 6px;
}
.bankBodyTxt {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.9px;
    color: #282828;
}
.flex_row {
    display: flex;
}
.flex_column {
    display: flex;
    flex-direction: column;
}
.uploadDocumentsBtn {
    max-width: 160px;
    cursor: pointer;
}
.docTxt {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.6px;
    color: #282828;
    opacity: 0.6;
    padding-top: 12px;
}
.unplankLogoPopup {
    width: 150px;
}
.linkPopUpHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.linkImg {
    display: block;
    margin: 0px auto 20px;
}
.linkTypes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    margin-left: 180px;
}
.linkSec {
    display: flex;
    align-items: center;
    padding: 2px 0px;
    img {
        width: 18px;
        margin-right: 10px;
    }
    .MuiTypography-root.MuiTypography-body1 {
        font-size: 14px;
        line-height: 26px;
        color: #242F3E;
        font-family: $plusJakartaSans-Regular;
    }
}
.linkDirectIDPopUp {
    .MuiDialog-paper {
        padding: 20px;
        background-color: #F4F9FE;
    }
    .MuiButton-label {
        text-transform: none;
    }
    .MuiTypography-h6 {
        font-size: 21px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.5px;
        color: $black;
        font-family: $plusJakartaSans-Bold;
    }
    .MuiTypography-body1 {
        font-family: $plusJakartaSans-SemiBold;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        letter-spacing: 1px;
        color: #242F3E;
        text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
    }
    .MuiDialogActions-root {
        justify-content: center;
    }
    .MuiButton-label {
        font-family: $plusJakartaSans-Bold;
    }
    .MuiButton-containedPrimary {
        background: #32B7E2;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0605059);
        border-radius: 10px;
        padding: 10px;
        font-size: 16px;
        text-align: center;
        letter-spacing: 0.8px;
        color: rgba(255, 255, 255, 0.9);
        width: 147px;
        height: 40px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .MuiButton-containedPrimary:hover {
        background-color: #32B7E2;
    }
    .MuiDialogTitle-root {
        padding: 40px 24px 10px;
    }
}
.linkBankPopUp {
    .MuiDialog-paper {
        padding: 16px 20px;
    }
    .MuiButton-label {
        text-transform: none;
    }
    .MuiTypography-h6 {
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.5px;
        color: $black;
        font-family: $plusJakartaSans-SemiBold;
    }
    .MuiButton-containedPrimary {
        background: #1A7CFA;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0605059);
        border-radius: 2px;
        padding: 10px;
        font-size: 16px;
        text-align: center;
        letter-spacing: 0.8px;
        color: #FFFFFF;
        font-family: $plusJakartaSans-Regular;
        font-weight: 700;
    }
    .MuiButton-containedPrimary:hover {
        background-color: $jumna-blue;
    }
    .MuiButton-outlinedPrimary {
        background: $white;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0605059);
        color: $jumna-blue;
        border-radius: 2px;
        padding: 10px;
        font-size: 16px;
        text-align: center;
        letter-spacing: 0.8px;
        font-family: $plusJakartaSans-Regular;
        font-weight: 700;
    }
    .MuiDialogActions-root {
        padding: 10px 25px 40px;
    }
    .MuiTypography-body1 {
        font-size: 12px;
        line-height: 24px;
        color: #242F3E;
        font-family: $plusJakartaSans-Regular;
    }
    .MuiDialogContent-root {
        padding: 15px 60px;
    }
    // .filepond--drop-label {
    //     border: 1.5px dashed $jumna-blue;
    //     border-radius: 2px;
    // }
}
.browseSec {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.browseTxt {
    display: flex;
    align-items: center;
    img {
        margin-right: 10px;
    }
    a{
        font-size: 10px;
        line-height: 13px;
        letter-spacing: 0.6px;
        color: #282828;
        opacity: 0.6;
        font-style: italic;
        text-decoration: none;
    }
}
.browseWrapper {
    //border: 1.5px dashed $jumna-blue;
    //border-radius: 2px;
    flex: 1;
    p {
        font-size: 14px;
        line-height: 12px;
        text-align: center;
        letter-spacing: 0.8px;
        color: #1A7CFA;
        font-family: $plusJakartaSans-SemiBold;
    }
}
.conformPopUp {
    .MuiDialog-paper {
        padding: 16px 20px;
    }
    .MuiButton-label {
        text-transform: none;
    }
    .MuiTypography-h6 {
        font-size: 21px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.5px;
        color: $black;
        font-weight: 700;
    }
    .MuiButton-containedPrimary {
        background: #1A7CFA;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0605059);
        border-radius: 2px;
        padding: 10px;
        font-size: 16px;
        text-align: center;
        letter-spacing: 0.8px;
        color: #FFFFFF;
        font-family: $plusJakartaSans-Regular;
        font-weight: 700;
    }
    .MuiButton-outlinedPrimary {
        background: $white;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0605059);
        color: $jumna-blue;
        border-radius: 2px;
        padding: 10px;
        font-size: 16px;
        text-align: center;
        letter-spacing: 0.8px;
        font-family: $plusJakartaSans-Regular;
        font-weight: 700;
    }
    .MuiDialogTitle-root {
        padding: 40px 60px;
    }
    .MuiDialogActions-root {
        padding: 10px 60px 40px;
    }
}
.close_img {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}
.mob_dashboard_sidenav {
    background-color: $jumna-blue;
    color: $white;
    padding: 2px 0px;
}
.selectInputTxt {
    .MuiFormLabel-root {
        font-size: 14px;
        letter-spacing: 0.7px;
        color: #282828;
        mix-blend-mode: normal;
        opacity: 0.5;
        font-family: $plusJakartaSans-Regular;
    }
}
.resetPasswordTxt {
    .MuiFormLabel-root {
        font-size: 14px;
        letter-spacing: 0.7px;
        color: #282828;
        mix-blend-mode: normal;
        opacity: 0.5;
        font-family: $plusJakartaSans-Regular;
    }
    .MuiTypography-body1 {
        font-weight: 600;
        font-size: 20px;
        color: #242f3e;
        font-family: $plusJakartaSans-Regular;
    }
    .MuiFormHelperText-root.Mui-error {
        margin-top: -10px;
    }
}
.switchButton {
    width: 47px;
    height: 23px;
    background: #cfdef5;
    border-radius: 56px;
    position: relative;
    margin-right: 10px;
}
.switchButtonMarker {
    width: 19px;
    height: 19px;
    background: $jumna-blue;
    border-radius: 50px;
    position: absolute;
    margin-left: 2px;
    z-index: 2;
    top: 2px;
}
.no_switch {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    left: 6px;
    z-index: 8;
}
.yes_switch {
    position: absolute;
    left: 30px;
    top: -1px;
    right: 0;
    bottom: 0;
    z-index: 8;
}
.passwordTxt_inactive {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #828282;
    font-family: $plusJakartaSans-Regular;
    margin-right: 20px;
}
.passwordTxt_active {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #2f363f;
    font-family: $plusJakartaSans-Regular;
    margin-right: 20px;
}
.otpTxt_active {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #2f363f;
    font-family: $plusJakartaSans-Regular;
}
.otpTxt_inactive {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #828282;
    font-family: $plusJakartaSans-Regular;
}
.tandcSec {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .MuiTypography-body1 {
        font-weight: 600;
        font-size: 22px;
        line-height: 28px;
        text-align: center;
        color: #000000;
        font-family: $plusJakartaSans-Bold;
    }
}
.tandcBox {
    width: 90%;
    height: 500px;
    overflow: auto;
    background: #fafafa;
    border: 1px solid rgba(84, 83, 83, 0.21);
    margin: 20px auto;
    padding: 30px 60px;
    text-align: justify;
    box-sizing: border-box;
    .MuiTypography-body1 {
        font-weight: 400;
        font-size: 14px;
        line-height: 29px;
        text-align: justify;
        letter-spacing: 0.7px;
        color: #282828;
        font-family: $plusJakartaSans-Regular;
    }
}
.accountStatusScroll {
    width: 99%;
    height: 59px;
    background: #F4F9FF;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 60px 0px 16px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    margin-left: 11px;
}
.scrollContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
        width: 15px;
        height: 15px;
        // margin-left: 20px;
        margin-left: 7px;
    }
    a {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #eb5757;
        font-family: $plusJakartaSans-Regular;
        display: flex;
        align-items: center;
    }
    p {
        //font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: #333F48;
        font-family: $plusJakartaSans-Bold;
        // margin-right: 24px;
        margin-right: 5px;
        span {
            color: #333f48;
            opacity: 0.8;
            //font-weight: 600;
            font-family: $plusJakartaSans-Bold;
        }
    }
}
.adminHeading {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 20px;
    .MuiTypography-body1 {
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.855294px;
        text-transform: uppercase;
        color: #000000;
        font-family: $plusJakartaSans-Bold;
    }
}
.detailsSec {
    padding: 30px;
    box-sizing: border-box;
}
.businessSec {
    width: 100%;
    height: 35px;
    background: #f4f4f4;
    padding: 10px 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 1.05412px;
    color: #282828;
}
.businessColumn {
    // display: flex;
    // flex-direction: column;
    //min-width: 300px;
    flex-basis: 30%;
}
.marginLeft {
    margin-left: 60px;
}
.underlineNone {
    text-decoration: none;
}
.textUnderline {
    color: $jumna-blue;
}
.otpSec {
    width: 90%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
   .MuiFormControl-root {
        width: 45px !important;
        margin-left: 5px;
        margin-right: 5px;
    }
}
// .businessDetails {
//     padding-right: 50px;
//     min-width: 250px;
// }
.businessLabel {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.5px;
    color: #333f48;
    font-family: $plusJakartaSans-Regular;
    margin-bottom: 5px;
}
.businessValue {
    font-weight: 400;
    font-size: 16px;
    color: #282828;
    font-family: $plusJakartaSans-Regular;
    letter-spacing: 0.9px;
    margin-bottom: 30px;
}
/* styles for tabs in rewards page */
.rewards-wrapper {
    .MuiTabs-flexContainer {
        justify-content: center;
    }
    a.MuiTab-textColorPrimary.Mui-selected,
    button.MuiTab-textColorPrimary.Mui-selected {
        color: #1a7cfa;
        span {
            font-family: $plusJakartaSans-Bold;
        }
    }
    .MuiTabs-indicator,
    .PrivateTabIndicator-colorPrimary-3 {
        background-color: #1a7cfa;
    }
    .MuiTab-wrapper {
        text-transform: none;
        font-size: 14px;
    }
    .MuiRadio-colorPrimary.Mui-checked {
        color: #1a7cfa;
    }
    .MuiSvgIcon-root,
    .MuiSvgIcon-root {
        width: 18px;
    }
    .rewardTab_options {
        .MuiTypography-root.MuiFormControlLabel-label {
            font-size: 14px;
            font-family: $plusJakartaSans-Regular;
        }
        .MuiFormControlLabel-root {
            margin-right: 45px;
        }
    }
}
// styles for tabs in admin page
.admin_wrapper {
    .MuiTabs-flexContainer {
        justify-content: center;
    }
    button.MuiTab-textColorPrimary.Mui-selected {
        color: #1a7cfa;
        span {
            font-family: $plusJakartaSans-Bold;
        }
    }
    .PrivateTabIndicator-colorPrimary-3 {
        background-color: #1a7cfa;
    }
    .MuiTab-wrapper {
        text-transform: none;
        font-size: 14px;
    }
    .MuiTabs-indicator {
        background-color: $jumna-blue;
    }
}
//Add discount styles
.add_discount {
    .MuiFormGroup-root {
        display: flex !important;
        justify-content: space-between;
        flex-direction: row !important;
    }
    .MuiFormControl-marginNormal {
        width: 100%;
    }
    .uploadfile {
        input {
            border: 1px dashed #ccc;
            padding: 10px;
            margin-top: 20px;
            border-radius: 5px;
            cursor: pointer;
        }
        &.errorFileUpload {
            input {
                border: 1px dashed red;
            }
        }
    }
    .select_desc {
        .MuiFormControl-root {
            min-width: 100%;
        }
    }
}
.discount_Item {
    width: 218px;
    box-shadow: 0 0 4px #ccc;
    //padding: 10px;
    box-sizing: border-box;
    margin: 12px 12px;
    min-width: 180px;
    min-height: 210px;
    margin-right: 20px;
    position: relative;
    border-radius: 3px;
}
.discount_imagewrapper {
    // min-height: 138px;
    min-height: 167px;
    display: "flex";
    align-items: "center";
    justify-content: "center";
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    border-radius: 3px;
    &::after {
        position: absolute;
        content: "";
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.79) 100.11%
        );
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}
.expireOnTxt {
    font-size: 12px;
    padding-left: 10px;
    line-height: 19px;
    color: #000000;
    opacity: 0.7;
    font-family: $plusJakartaSans-Regular;
    font-weight: 600;
}
// .listItemwrapper::after {
//     content: "";
//     flex: auto;
//     width: 29%;
// }

//loyality tab input
.select_desc {
    width: 45%;
}

.edit-wrapper input[type="number"]::-webkit-outer-spin-button,
.edit-wrapper input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@media screen and (max-width: 1140px) {
    .discount_Item {
        width: 40%;
        box-shadow: 0 0 4px #ccc;
        //padding: 10px;
        box-sizing: border-box;
        margin: 12px 12px;
        min-width: 180px;
        min-height: 220px;
    }
    .listItemwrapper::after {
        content: "";
        // flex: auto;
        width: 40%;
    }
}

// Landing page - marquee text styles
.marquee_text {
    text-transform: uppercase;
    -moz-animation: marquee 30s linear infinite;
    -webkit-animation: marquee 30s linear infinite;
    animation: marquee 30s linear infinite;
    white-space: nowrap;
}
@keyframes marquee {
    0% {
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    100% {
        -moz-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}
.marquee_wrapper {
    padding: 1px 0;
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    z-index: 1;
    bottom: 5px;
    left: 0;
    right: 0;
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    -moz-backdrop-filter: blur(6px);
}
.banner_image {
    max-width: 100%;
    overflow: hidden;
    position: relative;
    .mobileBanner {
        display: none;
    }
    .mobile-banner {
        display: none;
        background-color: #1a7cfa;
        background-image: linear-gradient(#1a7cfa 80%, #0180f7 10%);
        box-sizing: "border-box";
        margin-bottom: -2px;
        padding: 35px 25px 15px 25px;
        box-sizing: border-box;
    }
}
.eachSection {
    display: flex;
    margin-top: 40px;
    justify-content: space-between;
    flex-wrap: wrap;
}
.sectionTwo {
    align-items: center;
}
.sectionFour {
    background: #f4f9ff;
    padding: 65px 0 30px 0;
    margin-bottom: 40px;
    flex-wrap: wrap;
}
.section_one_text {
    flex: 0 0 50%;
    padding: 0 70px;
    box-sizing: border-box;
}
.section_two_text {
    flex: 0 0 48%;
    padding: 0 50px 0 10px;
    box-sizing: border-box;
}
.section_three_text {
    flex: 0 0 48%;
    padding: 0 70px;
    box-sizing: border-box;
}
.section_four_text {
    flex: 0 0 48%;
    padding: 0 70px;
    box-sizing: border-box;
}
.section_one_image {
    flex: 0 0 40%;
}
.section_two_image {
    flex: 0 0 46%;
}
.section_three_image {
    flex: 0 0 45%;
}
.section_four_image {
    flex: 0 0 50%;
}
.mobileSectionOne {
    display: none;
}
.footer_content {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    margin-bottom: 30px;
    align-items: center;
}
.navList {
    display: flex;
    p {
        margin: 0 15px;
    }
}

//login page styles
.login_btn {
    .MuiButton-label {
        font-family: $plusJakartaSans-Bold;
        text-transform: none;
    }
}

//loyalty page styles
.loyalty_wrapper {
    .add_tierBtn {
        border: 1px solid #1a7cfa;
        //width: 120px;
        padding: 8px 20px;
        border-radius: 0px;
        .MuiButton-label {
            font-family: $plusJakartaSans-Bold;
            text-transform: none;
        }
    }
    .loyalty_btn {
        .MuiButton-label {
            font-family: $plusJakartaSans-Bold;
            text-transform: none;
        }
    }
}

//Balance screen
.balance-wrapper {
    .MuiTabs-flexContainer {
        justify-content: center;
    }
    button.MuiTab-textColorPrimary.Mui-selected {
        color: #1a7cfa;
        span {
            font-family: $plusJakartaSans-Bold;
        }
    }
    .PrivateTabIndicator-colorPrimary-3 {
        background-color: #1a7cfa;
    }
    .MuiTab-wrapper {
        text-transform: none;
        font-size: 14px;
    }
    .MuiTabs-root {
        border-bottom: 1px solid #ccc;
        margin: 0 20px;
    }
    .MuiPaper-elevation1 {
        box-shadow: none;
    }
    .MuiTab-root {
        min-width: 210px;
    }
    .amountBlockWrapper {
        padding: 8px 20px;
        flex-wrap: wrap;
    }
    .balancePill {
        padding: 15px;
        span.fontBold {
            margin-left: 10px;
            width: "33%";
        }
    }
}

//transfer tab styles
.transfer_main {
    padding: 24px 18px;
    .balance_radio {
        span.MuiFormControlLabel-label {
            font-size: 14px;
        }
    }
    .initiate_button {
        .MuiButton-label {
            text-transform: none;
            font-family: $plusJakartaSans-Bold;
            font-size: 14px;
        }
    }
    .datePicker {
        .MuiFormControl-root {
            width: 100%;
        }
    }
    .eachTransfer_Section {
        padding: 30px 25px;
    }
    .transferFormWrapper {
        display: flex;
        margin-left: 40px;
        align-items: center;
        flex-wrap: wrap;
    }
    .transferFormContainer {
        flex: 1;
        padding: 20px 50px 50px 50px;
        box-sizing: border-box;
    }
    .amountInput {
        display: flex;
        align-items: flex-end;
    }
}

//header styles
.mobileHeader {
    display: none;
}

//Create Merchant styles
.createMerchant {
    .MuiFormControl-root {
        width: 100%;
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before,
    .MuiInput-underline:after {
        border-bottom: 2px solid $jumna-blue;
    }
}
.signupWrapper {
    background-color: #f0f5fd;
    min-height: 90vh;
    .MuiInputLabel-root,
    .MuiInputBase-input {
        font-size: 14px;
    }
    .customBtn {
        width: 200px;
    }
    .date-picker {
        .MuiFormControl-root {
            width: 100%;
        }
    }
}

// OTP screen styles
.otp-input {
    input {
        text-align: center;
    }
}

.otpTextField {
    .MuiInputBase-input {
        text-align: center;
    }
}
//Dashboard main styles
.dashboardMain {
    margin-left: 200px;
    // padding-bottom: 40px;
    // padding-top: 10px;
    .dashboardContent{
        height: calc(100vh - 64px);
        overflow: auto;
        display: flex;
        flex-direction: column;
        .verifyAccountPopUp, .paginationSec{
            flex: 0 1 auto;
        }
        .creditContainer{
            flex: 1 1 auto;
            overflow: auto;
            display: flex;
            flex-direction: column;
            .headerText{
                flex: 0 1 auto;
            }
            .transactionTable{
                flex: 1 1 auto;
                overflow: auto;
                .MuiTable-root{
                    max-height: 100%;
                    overflow: auto;
                }
                .MuiTableContainer-root{
                    height: 100%;
                }
            }
        }
    }
}
//loyality table
.table_mobile {
    display: none !important;
}
//loyality table end

// new home screen code start
.home_banner{
    position: relative;
    height: 108vh;
    max-height: 760px;
    img {
        width: 100%;
        height: 108vh;
        max-height: 760px;
        object-fit: cover;
        // position: absolute;
        // z-index: -1;
    }
}
.banner_signup{
    width: 578px;
    height: 546px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 55px 60px 80px 48px;
    background: #FFFFFF;
    box-shadow: 0px 0px 24px #606060;
    border-radius: 6px;
    position: absolute;
    top: 107px;
    left: 144px;
    h2 {
        font-family: $plusJakartaSans-Bold;
        font-size: 58px;
        line-height: 68px;
        letter-spacing: -0.02em;
        color: $black;
        margin-block-start: 0px;
        margin-block-end: 0px;
    }
    h4 {
        font-family: $plusJakartaSans-Bold;
        font-size: 20px;
        line-height: 25px;
        letter-spacing: -0.02em;
        color: $home-sub-text;
        margin-block-start: 20px;
        margin-block-end: 8px;
    }
    .bannerSubText {
        font-family: $plusJakartaSans-Regular;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.02em;
        color: $home-sub-opacity;
        margin-block-start: 0px;
        margin-block-end: 61px;
    }
    .joinPilotBtn {
        h3 {
            font-family: $plusJakartaSans-SemiBold;
            font-size: 18px;
            text-align: center;
            color: $white;
            margin-block-start: 0px;
            margin-block-end: 0px;
        }
        .MuiTypography-body1 {
            font-family: $plusJakartaSans-Regular;
            font-size: 13px;
            line-height: 16px;
            margin-block-end: 4px;
        }
    }
    .MuiButton-contained:hover,
    .MuiButton-contained{
        background-color: $jumna-blue;
        color: $white;
    }
    .MuiButton-root{
        border-radius: 2px;
        text-transform: none;
        padding: 16.5px 16px ;
        width: 350px;
    }
    .MuiButton-outlined:hover,
    .MuiButton-outlined {
        border: 1px solid $jumna-blue;
        background-color: $white;
        color: $jumna-blue;
        padding: 10px 16px;
        margin-top: 18px;
        font-size: 18px;
        font-weight: 600;
        font-family: $plusJakartaSans-SemiBold;
    }
    // .btnText {
    //     font-size: 18px;
    //     font-family: $plusJakartaSans-SemiBold;
    // }
    .faqLink {
        font-family: $plusJakartaSans-SemiBold;
        font-size: 18px;
        line-height: 23px;
        color: $jumna-blue;
        text-decoration-line: underline;
        letter-spacing: -0.02em;
        margin-top: 33px;
    }
}
.app_to_app_sec {
    background: #232F3E;
    //background: #263647;
    //height: 760px;
    display: flex;
    flex-direction: row;
    padding-top: 30px;
    padding-bottom: 30px;
    .grid_top {
        margin-top: 200px;
    }
    .app_to_app_content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        //margin-top: 75px;
        h2 {
            font-family: $plusJakartaSans-Bold;
            font-size: 42px;
            line-height: 53px;
            color: $white;
            margin-block-start: 0px;
            margin-block-end: 20px;
        }
        h4 {
            font-family: $plusJakartaSans-Bold;
            font-size: 20px;
            line-height: 25px;
            color: $white;
            margin-block-start: 0px;
            margin-block-end: 8px;
        }
        p {
            font-family: $plusJakartaSans-Regular;
            font-size: 16px;
            line-height: 20px;
            color: $white;
            margin-block-start: 0px;
            margin-block-end: 0px;
        }
    }
    img {
        height: 90vh;
        margin-top: 40px;
    }
    button {
        width: 405px;
        margin-top: 60px;
    }
    .MuiButton-contained:hover,
    .MuiButton-contained{
        background-color: $jumna-blue;
        color: $white;
        padding: 16px 20px;
        font-weight: 600;
    }
    .MuiButton-root{
        border-radius: 2px;
        text-transform: none;
    }
    .MuiButton-label {
        font-size: 22px;
        font-family: $plusJakartaSans-Bold;
    }
}
.nochangeSec {
    padding-top: 0px;
    padding-bottom: 80px;
}
.payment_sec {
    padding-top: 70px;
    padding-bottom: 30px;
}
.payment_method_sec {
    display: flex;
    flex-direction: row;
    background-color: $white;
}
.payment_method_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 60px 56px;
    width: 40%;
    margin: auto;
    h2 {
        font-family: $plusJakartaSans-Bold;
        font-size: 42px;
        line-height: 53px;
        color: $black;
        margin-block-start: 0px;
        margin-block-end: 20px;
    }
    h4 {
        font-family: $plusJakartaSans-Bold;
        font-size: 20px;
        line-height: 25px;
        color: $home-sub-text;
        margin-block-start: 0px;
        margin-block-end: 8px;
    }
    p {
        font-family: $plusJakartaSans-Regular;
        font-size: 16px;
        line-height: 20px;
        color: $home-sub-opacity;
        margin-block-start: 0px;
        margin-block-end: 0px;
        // span {
        //     text-decoration: underline;
        //     text-decoration-color: $jumna-blue;
        // }
    }
}
.gradientMain {
    margin-top: 10px;
    margin-bottom: 16px;
    width: 100%;
}
.gradient_sec {
    background: linear-gradient(108.5deg, #197AF8 28.69%, #0D4EA5 94.49%);
    border-radius: 6px;
    padding: 40px 100px;
    width: 83.4%;
    margin: auto;
    img {
        margin-left: 20px;
    }
    h2 {
        font-family: $plusJakartaSans-Bold;
        font-size: 42px;
        line-height: 53px;
        color: $white;
    }
    .gradient_content {
        .beatText {
            font-family: $plusJakartaSans-Bold;
            font-size: 32px;
            line-height: 40px;
            color: $white;
            margin-top: 10px;
            margin-bottom: 20px;
        }
        p{
            font-family: $plusJakartaSans-Regular;
            font-size: 20px;
            line-height: 25px;
            color: $white;
            margin-block-start: 0px;
            margin-block-end: 0px;
        }
    }
    .gradient_content{
        padding: 10px 0px 50px 50px;
    }
}
.chat_with_cust_sec{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    img {
        // margin-right: 60px;
    }
    .chat_text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 99px;
        h2 {
            font-family: $plusJakartaSans-Bold;
            font-size: 42px;
            line-height: 53px;
            color: $black;
            letter-spacing: -0.02em;
            margin-bottom: 20px;
        }
        h4 {
            font-family: $plusJakartaSans-Bold;
            font-size: 20px;
            line-height: 25px;
            color: $home-sub-text;
            margin-block-start: 0px;
            margin-block-end: 8px;
        }
        p {
            font-family: $plusJakartaSans-Regular;
            font-size: 16px;
            line-height: 20px;
            color: $home-sub-opacity;
            margin-block-start: 0px;
            margin-block-end: 0px;
        }
    }
}
.business_content {
    .tick_sec {
        padding-top: 60px;
        width: 87%;
    }
    .tick_spacing {
        padding-bottom: 40px;
    }
    .tick_content {
        span {
            font-family: $plusJakartaSans-Regular;
            font-size: 25px;
            line-height: 32px;
            letter-spacing: -0.02em;
            color: $home-sub-text;
        }
    }
    p {
        font-family: $plusJakartaSans-Regular;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.02em;
        color: $home-sub-text;
        padding-left: 46px;
        margin-block-start: 8px;
        margin-block-end: 0px;
    }
    img {
        width: 20px;
        height: 20px;
        margin-right: 12px;
    }
}
.attract_sec {
    padding-top: 0px;
    padding-bottom: 100px;
}
.key_sec {
    padding-top: 70px;
    padding-bottom: 60px;
}
.data_privacy_sec {
    display: flex;
    flex-direction: row;
    gap: 12rem;
    img {
        margin-right: 50px;
    }
    .data_privacy_text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 50px;
        .MuiTypography-h1 {
            font-family: $plusJakartaSans-Bold;
            font-size: 42px;
            line-height: 65px;
            color: $black;
            margin-block-start: 0px;
            margin-block-end: 20px;
        }
        h2 {
            font-family: $plusJakartaSans-Bold;
            font-size: 20px;
            line-height: 25px;
            color: $home-sub-text;
            margin-block-start: 0px;
            margin-block-end: 8px;
        }
        p {
            font-family: $plusJakartaSans-Regular;
            font-size: 16px;
            line-height: 20px;
            color: $home-sub-text;
            margin-block-start: 0px;
            margin-block-end: 0px;
        }
    }
}
.know_more_sec {
    padding: 50px;
    .attract_sub_text {
        font-family: $plusJakartaSans-Bold;
        font-size: 20px;
        line-height: 25px;
        color: $home-sub-text;
        padding-top: 0px;
        margin-block-start: 0px;
        margin-block-end: 8px;
    }
    h2 {
        font-family: $plusJakartaSans-Bold;
        font-size: 42px;
        line-height: 65px;
        color: $black;
        margin-block-end: 16px;
    }
    p {
        font-family: $plusJakartaSans-Regular;
        font-size: 16px;
        line-height: 20px;
        color: $home-sub-text;
        margin-block-start: 0px;
        margin-block-end: 0px;
    }
    .MuiButton-contained:hover,
    .MuiButton-contained{
        background-color: $jumna-blue;
        color: $white;
        width: 360px;
        height: 65px;
        margin-top: 60px;
    }
    .MuiButton-root{
        border-radius: 2px;
        text-transform: none;
        padding: 12px 14px;
        font-weight: 600;
        font-size: 18px;
    }
}
.five_easy_steps_sec {
    background-color: #F4F9FF;
    padding: 80px 0px;
}
.steps_sec {
    display: flex;
    flex-direction: row;
    width: 100%;
    img {
        width: 80%;
    }
    .five_easy_steps {
        width: 20%;
    }
}
.five_easy_steps{
    padding: 50px 40px;
    h2 {
        font-family: $plusJakartaSans-Bold;
        font-size: 30px;
        line-height: 38px;
        color: $black;
    }
    p {
        color: rgba(26, 124, 250, 0.8);
        font-family: $plusJakartaSans-SemiBold;
        font-size: 24px;
        line-height: 30px;
        margin-right: 10px;
        margin-block-start: 4px;
        margin-block-end: 4px
    }
    .setUpText {
        margin-bottom: 15px;
        span {
            font-family: $plusJakartaSans-Regular;
            font-size: 20px;
            line-height: 30px;
            color: $home-sub-text;
            margin-block-start: 4px;
            margin-block-end: 4px
        }
    }
}
.blue_tick_img {
    width: 22px;
    height: 22px;
    margin: 12px;
}
.attract_img {
    width: 692px;
    height: auto;
    // width: 532px;
    // height: 532px;
}
.attract_last_img {
    height: auto;
    width: 532px;
    margin-left: 50px;
}
// new home screen code End
// shop with unplank code start
.landing_main_sec {
    max-width: 100%;
    overflow: hidden;
    position: relative;
}
.shopUnplank_banner_sec{
    position: relative;
    height: auto;
    img {
        width: 100%;
        //height: 90vh;
        object-fit: fill;
        // position: absolute;
        // z-index: -1;
    }
    .shopUnplank_banner_text {
        position: absolute;
        top: 0px;
        padding-top: 14vh;
        padding-left: 144px;
        h2 {
            font-family: $plusJakartaSans-Bold;
            font-size: 42px;
            line-height: 53px;
            color: $white;
        }
        h4 {
            font-family: $plusJakartaSans-Bold;
            font-size: 32px;
            line-height: 40px;
            color: $white;
        }
        p {
            font-family: $plusJakartaSans-SemiBold;
            font-size: 24px;
            line-height: 30px;
            color: $white;
        }
        .MuiButton-contained:hover,
        .MuiButton-contained{
            background-color: $jumna-blue;
            color: $white;
            width: 360px;
        }
        .MuiButton-root{
            border-radius: 2px;
            text-transform: none;
            padding: 12px 16px;
            font-size: 18px;
            font-family: $plusJakartaSans-SemiBold;
            font-weight: 600;
        }
    }
}
.credit_content {
    padding-top: 12vh;
    padding-right: 16vh;
    h2 {
        font-family: $plusJakartaSans-Bold;
        font-size: 32px;
        line-height: 40px;
        color: $black;
    }
    p {
        font-family: $plusJakartaSans-Regular;
        font-size: 20px;
        line-height: 25px;
        color: $home-sub-text;
    }
}
.credit_sec {
    margin: 50px auto;
    width: 80%;
}
.credit_type_sec {
    //background: linear-gradient(307.42deg, #197AF8 11.63%, #0D4EA5 85.4%);
    background: linear-gradient(108.5deg, #197AF8 11.63%, #0D4EA5 85.4%);
    border-radius: 6px;
    padding: 40px;
    img {
        margin-right: 20px;
    }
    p {
        font-family: $plusJakartaSans-Regular;
        font-size: 22px;
        line-height: 33px;
        color: $white;
        margin-block-start: 30px;
        margin-block-end: 30px;
    }
}
.transact_ease_sec {
    display: flex;
    flex-direction: row;
    gap: 84px;
    margin-top: 50px;
    margin-bottom: 50px;
}
.transact_ease_content {
    padding-top: 3em;
    h2 {
        font-family: $plusJakartaSans-Bold;
        font-size: 32px;
        line-height: 40px;
        color: $black;
    }
    p {
        font-family: $plusJakartaSans-Regular;
        font-size: 20px;
        line-height: 35px;
        color: $home-sub-text;
    }
}
// landing page code start
.max-width {
    max-width: 1360px;
    margin: auto;
    padding-left: 60px;
    padding-right: 60px;
}
.createAccountCard {
    // display: flex;
    // justify-content: flex-end;
    margin-top: 20px;
    background-color: $jumna-blue;
    width: 100%;
    .MuiButton-contained {
        background-color: $white;
        color: $jumna-blue;
        box-shadow: 0px 5px 24px rgba(0, 0, 0, 0.33);
    }
    .MuiButton-root {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.02em;
        text-transform: none;
        border-radius: 2px;
        padding: 12px;
        width: 220px;
    }
    .MuiButton-label {
        font-family: $plusJakartaSans-Bold;
    }
    .MuiButton-contained:hover {
        box-shadow: none;
        background-color: $white;
    }
}
.bannerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 82%;
    margin: auto;
}
.homeBannerSec {
    display: flex;
    align-items: center;
}
.homeBannerContent {
    width: 185px;
    height: 120px;
    background: rgba(255, 255, 255, 0.26);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 50px;
    cursor: pointer;
    .MuiTypography-body1 {
        font-size: 14px;
        font-family: $plusJakartaSans-SemiBold;
        color: $white;
        line-height: 18px;
    }
    .videoImg,
    .cursorImg {
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    }
}
.createAccountSec {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //width: 310px;
    box-sizing: border-box;
    padding: 30px 40px;
    p {
        font-size: 14px;
        font-family: $plusJakartaSans-Medium;
        color: $white;
        line-height: 20px;
        //margin-block-end: 0px;
    }
}
.expressTxt {
    font-size: 16px;
    font-family: $plusJakartaSans-Regular;
    color: $white;
    line-height: 20px;
    margin-bottom: 12px;
    padding-top: 10px;
    span {
        font-family: $plusJakartaSans-Bold;
    }
}
.acceptTxt {
    font-size: 14px;
    font-family: $plusJakartaSans-Medium;
    color: $white;
    line-height: 20px;
}
.banner_section {
    padding: 0px 0px;
    height: 75vh;
    padding-top: 30vh;
    .banner_content {
        h2 {
            font-weight: 700;
            font-size: 70px;
            line-height: 88px;
            color: #242f3e;
            margin-bottom: 0px;
            margin-top: 10px;
            font-family: "PlusJakartaSans-Bold";
        }
        p {
            font-weight: 400;
            font-size: 38px;
            line-height: 48px;
            color: #242f3e;
            max-width: 80%;
            width: 100%;
            font-family: "PlusJakartaSans-Regular";
        }
    }
}
.banner_section_home {
    padding: 0px 0px;
    //height: 50vh;
    padding-top: 10vh;
    padding-bottom: 10vh;
    .banner_content {
        h2 {
            font-weight: 700;
            font-size: 70px;
            line-height: 88px;
            color: #242f3e;
            margin-bottom: 0px;
            margin-top: 10px;
            font-family: "PlusJakartaSans-Bold";
        }
        p {
            font-weight: 400;
            font-size: 38px;
            line-height: 48px;
            color: #242f3e;
            max-width: 80%;
            width: 100%;
            font-family: "PlusJakartaSans-Regular";
        }
    }
}
.desktop_none {
    display: none;
}

.playstore_sec {
    // background: #f4f9fe;

    // padding-bottom: 55px;
    // padding-top: 50px;
    // text-align: center;
    height: 791px;
    background: #f4f9fe;
    // padding-bottom: 45px;
    // padding-top: 18px;
    text-align: center;

    img {
        // width: 100%;
        // width: auto;
        // margin: 0px auto;
        width: 947px;

        margin: 0px auto;
    }
    h2 {
        font-weight: 700;
        font-size: 58px;
        line-height: 73px;
        letter-spacing: 0.02em;
        color: #242f3e;
        text-align: center;
        margin-bottom: 10px;
        margin-top: 0px;
        font-family: "PlusJakartaSans-Bold";
    }
}
.control_sec {
    background: #fbfbfd;
    padding-top: 127px;
    padding-bottom: 127px;
    margin-top: 50px;
    h2 {
        font-family: "PlusJakartaSans-Regular";
        font-weight: 500;
        font-size: 48px;
        line-height: 60px;
        color: #242f3e;
    }
    p {
        font-family: "PlusJakartaSans-Regular";
        font-weight: 400;
        font-size: 28px;
        line-height: 35px;
        color: #242f3e;
        width: 88%;
    }
}
.sign_up_sec {
    // padding-top: 50px;
    // padding-bottom: 40px;
    padding-top: 100px;
    padding-bottom: 40px;
    .max-width {
        padding-left: 110px;
    }

    h2 {
        font-weight: 500;
        font-size: 28px;
        line-height: 35px;
        letter-spacing: -0.02em;
        color: #242f3e;
        font-family: "PlusJakartaSans-Medium";
    }
    .MuiInputLabel-outlined {
        // transform: translate(14px, 28px) scale(1);
        transform: translate(14px, 18px) scale(1);
        font-weight: 400;
        // font-size: 18px;
        font-size: 16px;
        line-height: 23px;
        letter-spacing: -0.02em;
        color: #333f48;
        background: #fff;
        font-family: "PlusJakartaSans-Regular";
    }
    .MuiInputLabel-outlined.Mui-focused {
        transform: translate(14px, -10px) scale(1);
        background: #fff;
    }
    .MuiFormLabel-root.Mui-focused {
        color: #1a7cfa;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #1a7cfa;
    }
    input {
        // height: 40px;
        height: 25px;
        border: 1px solid #242f3e;
        border-radius: 2px;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: -0.02em;
        color: #333f48;
    }
    .MuiInputBase-formControl {
        max-width: 440px;
        width: 100%;
    }
    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: -0.02em;
        color: #333f48;
        max-width: 440px;
        width: 100%;
        text-align: center;
    }
    .create_btn {
        background: #1a7cfa;
        border-radius: 2px;
        max-width: 440px;
        width: 100%;
        text-transform: capitalize;
        height: 67px;
        margin-top: 35px;
        &:hover {
            background: #1a7cfa;
        }
        span {
            font-weight: 600;
            font-size: 20px;
            line-height: 25px;
            letter-spacing: -0.02em;
            color: #ffffff;
        }
    }
    .sign_up_img {
        width: 100%;
        // height: 550px;
        height: 590px;
        object-fit: cover;
    }
}
.modal_form_list {
    .MuiFormLabel-root.Mui-focused {
        color: #1a7cfa;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #1a7cfa;
    }
}
.get_the_credit {
    margin-top: 4px;
    .credit_left {
        background: #242f3e;
        // padding: 165px 98px;
        // padding: 154px 98px;
        padding: 142px 98px;

        p {
            font-weight: 400;
            font-size: 28px;
            line-height: 35px;
            letter-spacing: -0.02em;

            color: #ffffff;
            max-width: 500px;
            width: 100%;
            margin-top: 35px;
            font-family: "PlusJakartaSans-Regular";
            display: flex;
            align-items: baseline;
            img {
                margin-right: 28px;
            }
        }
    }
    .credit_right {
        display: flex;
        height: 100%;
        align-items: center;
        h2 {
            font-weight: 500;
            font-size: 38px;
            line-height: 48px;
            letter-spacing: -0.02em;
            color: #242f3e;
            max-width: 265px;
            width: 100%;
            margin: 0px auto;
            font-family: "PlusJakartaSans-Medium";
            margin-top: 100px;
            margin-bottom: 100px;
        }
    }
}
.payment_left {
    text-align: center;
    img {
        // max-width: 420px;
        // width: 100%;
        // margin-top: 130px;
        // margin-bottom: 130px;
        max-width: 430px;
        width: 100%;
        margin-top: 100px;
        margin-bottom: 52px;
    }
}
.payment_right {
    background: #f4f9fe;
    padding: 88px;
    /* padding-bottom: 115px; */
    padding-top: 0px;
    background: #f4f9fe;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    // height: 100%;
    height: 767px;
    padding-bottom: 0px;
    h2 {
        font-weight: 500;
        // font-size: 42px;
        // line-height: 53px;
        font-size: 38px;
        line-height: 48px;
        letter-spacing: -0.02em;
        color: #242f3e;
        font-family: "PlusJakartaSans-Medium";
    }
    p {
        font-weight: 400;
        font-size: 28px;
        line-height: 35px;
        letter-spacing: -0.02em;
        color: #242f3e;
        max-width: 515px;
        width: 100%;
        font-family: "PlusJakartaSans-Regular";
        margin-top: 0px;
    }
}

.network_merchant_sec {
    // padding-top: 45px;
    padding-top: 0px;
    padding-bottom: 45px;
    h2 {
        font-weight: 700;
        font-size: 42px;
        line-height: 53px;
        letter-spacing: -0.02em;
        color: #242f3e;
        font-family: "PlusJakartaSans-Bold";
        margin-top: 13px;
        margin-bottom: 0px;
    }
    p {
        font-weight: 400;
        font-size: 25px;
        line-height: 32px;
        color: #242f3e;
        width: 88%;
        margin-top: 15px;
        font-family: "PlusJakartaSans-Regular";
        margin-bottom: 0px;
    }
    .merchants_sub_sec {
        // margin-top: 50px;
        margin-top: 0px;
    }
}
.our_inteligent_sec {
    // background: #f8f8ff;
    padding-top: 118px;
    padding-bottom: 117px;
    h2 {
        font-family: "PlusJakartaSans-Bold";
        font-size: 42px;
        line-height: 53px;
        color: #242f3e;
    }
    p {
        font-family: "PlusJakartaSans-Regular";
        font-weight: 400;
        font-size: 28px;
        line-height: 35px;
        color: #242f3e;
    }
}
// footer section start
.landing_footer_sec {
    background: $home-sub-text;
    padding-top: 30px;
    padding-bottom: 30px;
    .footer_sec {
        // padding: 0px 80px;
        display: grid;
        grid-template-columns: 60% 40%;
        grid-gap: 20px;

        .footer_list_sec {
            .footer_list_inner {
                display: grid;
                grid-template-columns: 50% 50%;
            }
            .footer_list {
            }
        }
    }
    .footer_left_sec {
        img {
            width: 200px;
        }
        p {
            font-family: $plusJakartaSans-Light;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.01em;
            color: #ffffff;
            opacity: 0.9;
            max-width: 565px;
            width: 100%;
            margin-top: 25px;
        }
    }
    .footer_list {
        padding-top: 108px;
        ul {
            list-style: none;
            li {
                font-family: $plusJakartaSans-Light;
                font-size: 16px;
                line-height: 20px;
                color: #ffffff;

                opacity: 0.9;
                padding-top: 10px;
                padding-bottom: 10px;
                cursor: pointer;
            }
        }
    }
    .copy_right_sec {
        //padding-left: 38px;
        margin-top: 30px;
        //margin-top: 45px;

        span {
            font-family: $plusJakartaSans-Light;
            font-size: 16px;
            line-height: 20px;
            color: #ffffff;
            opacity: 0.8;
        }
        a {
            font-family: $plusJakartaSans-Light;
            font-size: 13px;
            line-height: 20px;
            color: #ffffff;
            opacity: 0.8;
            padding-left: 27%;
            text-decoration: none;
        }
    }
}
.social_links_sec {
    padding-left: 38px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    p{
        font-family: $plusJakartaSans-Light;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
        color: $white;
        opacity: 0.9;
    }
    img {
        width: 34px;
        height: 34px;
        margin-right: 16px;
        cursor: pointer;
    }
}

// merchant landing page code start
.merchant_landing_sec {
    max-width: 100%;
    overflow: hidden;
    position: relative;
    .merchant_banner_sec {
        position: relative;
        height: 110vh;

        img {
            width: 100%;
            height: 110vh;
            object-fit: cover;
            position: absolute;
            z-index: -1;
        }
        .merchant_banner_text {
            // position: absolute;
            // top: 60%;
            // left: 44%;
            // width: 78%;
            // transform: translate(-50%, -50%);
            padding-top: 30vh;
            h2 {
                font-family: "PlusJakartaSans-Bold";
                font-size: 70px;
                line-height: 88px;
                color: #ffffff;
            }
            p {
                font-family: "PlusJakartaSans-Light";
                font-size: 38px;
                line-height: 48px;
                color: #ffffff;
            }
        }
        .heading_tag_line {
            // position: absolute;
            // top: 0px;
            // left: 40px;
        }
    }
    .easy_steps_sec {
        .easy_step_left {
            display: flex;
            height: 100%;
            align-items: center;
            h2 {
                font-family: "PlusJakartaSans-Bold";
                font-weight: 700;
                font-size: 42px;
                line-height: 53px;
                color: #242f3e;
                width: 365px;
                margin: 0px auto;
            }
        }
        .easy_step_right {
            background: #f4f9fe;
            padding: 135px 100px;
            padding-bottom: 209px;
            // padding: 172px 100px;
            // ul {
            //     li {
            //         font-family: "PlusJakartaSans-Light";
            //         font-size: 38px;
            //         line-height: 50px;
            //         letter-spacing: 0.02em;
            //         color: #242f3e;
            //         margin-bottom: 10px;
            //         margin-top: 0px;
            //         list-style-type: decimal;
            //     }
            // }
            h2 {
                font-family: "PlusJakartaSans-Regular";
                font-weight: 400;
                font-size: 28px;
                line-height: 35px;
                letter-spacing: 0.02em;
                color: #242f3e;
                margin-top: 30px;
                display: flex;
                align-items: baseline;
                img {
                    margin-right: 28px;
                }
            }
            p {
                font-family: "PlusJakartaSans-Regular";
                font-weight: 400;
                font-size: 25px;
                line-height: 33px;
                letter-spacing: 0.02em;
                color: #242f3e;
                margin-top: 40px;
            }
        }
    }
    .technology_sec {
        padding-top: 92px;
        padding-bottom: 92px;
        h2 {
            font-family: "PlusJakartaSans-Bold";
            font-weight: 700;
            font-size: 42px;
            line-height: 53px;
            letter-spacing: -0.02em;
            color: #333f48;
            width: 90%;
            // margin-left: 88px;
            // margin: 0px auto;
            margin-top: 35px;
        }
    }
}
.top_fetures_left.ul_circle_sec {
    h2 {
        margin-top: 0px;
        margin-bottom: 10px;
    }
}
.top_features_sec {
    .top_fetures_left {
        padding-left: 80px;

        h2 {
            font-family: "PlusJakartaSans-Bold";
            font-size: 42px;
            line-height: 53px;
            letter-spacing: -0.02em;
            color: #242f3e;
        }
        p {
            font-family: "PlusJakartaSans-Regular";
            font-weight: 400;
            font-size: 28px;
            line-height: 48px;
            letter-spacing: -0.02em;
            color: #242f3e;
            display: flex;
            align-items: baseline;
            margin-bottom: 0px;
            margin-top: 20px;

            img {
                margin-right: 25px;
            }
        }
    }
    .top_fetures_right {
        img {
            width: 100%;
        }
    }
}
.easy_steps_sec.tailor_sec {
    .easy_step_left.tailor_inner_sec {
        h2 {
            // max-width: 405px;
            // width: 100%;
            max-width: 415px;
            width: 100%;
            font-family: "PlusJakartaSans-Bold";
            font-weight: 700;
        }
    }
    .easy_step_right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // height: 580px;
        height: 423px;
        h2 {
            margin-top: 35px;
            // font-size: 38px;
            // line-height: 48px;
            font-size: 35px;
            line-height: 43px;
        }
    }
}
.reward_sec {
    padding-top: 236px;
    padding-bottom: 235px;
    h2 {
        width: 90%;
    }
    p {
        margin-top: 40px;
    }
}
.feature_dflex {
    display: flex;
    height: auto;
    align-items: center;
}
.ul_circle_sec {
    ul {
        li {
            font-family: "PlusJakartaSans-Light";
            font-size: 28px;
            line-height: 48px;
            letter-spacing: -0.02em;
            color: #242f3e;
            margin-top: 10px;
            list-style-type: none;
            position: relative;
            padding-left: 8px;
        }
    }
}
.ul_circle_sec ul li::after {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid #1a7cfa;
    top: 13px;
    left: -30px;
}
.top_fetures_right.app_highlights {
    text-align: center;
    background: #f4f9fe;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    img {
        object-fit: cover;
        margin: 0px auto;
        max-width: 448px;
        width: 100%;
        margin-top: 100px;
        margin-bottom: 31px;
    }
}
.merchant_break_sec {
    .merchant_break_banner {
        position: relative;
        img {
            width: 100%;
        }
        .merchant_break_banner_text {
            position: absolute;
            bottom: -12%;
            // left: 33%;
            left: 31%;
            width: 52%;
            transform: translate(-50%, -50%);
            h2 {
                font-family: "PlusJakartaSans-Bold";
                font-size: 42px;
                line-height: 53px;
                letter-spacing: -0.02em;
                // max-width: 690px;
                // width: 100%;
                color: #ffffff;
            }
        }
    }
}
.ditch_sec {
    padding-top: 0px;
    padding-bottom: 70px;
    .ditch_inner_sec {
        background: #242f3e;
        .ditch_left_sec {
            padding-top: 40px;
            padding-left: 70px;
            img {
                width: 100%;
            }
        }
        .ditch_right_sec {
            h2 {
                font-family: "PlusJakartaSans-Bold";
                font-size: 42px;
                line-height: 53px;
                letter-spacing: -0.02em;
                color: #ffff;
                max-width: 640px;
                width: 100%;
                padding-left: 55px;
            }
        }
    }
    .ditch_card_sec {
        width: 95%;
        padding-top: 50px;
        padding-bottom: 50px;
        p {
            font-family: "PlusJakartaSans-Regular";
            font-size: 28px;
            line-height: 38px;
            letter-spacing: -0.02em;
            color: #242f3e;
        }
    }
}
.easy_steps_sec.steps_share_wallet {
    .easy_step_left {
        background: #242f3e;
        h2 {
            color: #fff;
        }
    }
    .easy_step_right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // height: 600px;
        height: 423px;

        h2 {
            // font-size: 38px;
            // line-height: 47px;
            font-size: 35px;
            line-height: 43px;
        }
    }
}
.steps_wallet_sec {
    .steps_wallet_banner {
        position: relative;
        height: 813px;
        img {
            width: 100%;
        }
        .steps_wallet_banner_text {
            position: absolute;
            top: 52%;
            left: 37%;
            transform: translate(-50%, -50%);
            h2 {
                font-family: "PlusJakartaSans-Bold";
                font-size: 58px;
                line-height: 73px;
                letter-spacing: -0.02em;
                color: #242f3e;
                margin-top: 0px;
                margin-bottom: 8px;
            }
            .steps_wallet_sub_sec {
                margin-top: 50px;
                max-width: 960px;
                p {
                    font-family: "PlusJakartaSans-Light";
                    font-size: 30px;
                    line-height: 38px;
                    letter-spacing: -0.02em;
                    color: #242f3e;
                }
            }
        }
    }
}
// merchant landing page code end

// lenders landing page code start
.lenders_landing_sec {
    max-width: 100%;
    overflow: hidden;
    position: relative;
    .embedded_sec {
        .embedded_left {
            background: #242f3e;
            padding: 184px 112px;
            h2 {
                font-family: "PlusJakartaSans-Regular";
                font-size: 38px;
                line-height: 48px;
                letter-spacing: -0.02em;
                color: #ffffff;
                font-weight: 400;
            }
        }
        .embedded_right {
            display: flex;
            align-items: center;
            height: 100%;
            justify-content: center;
            flex-direction: row;
            img {
                height: 550px;
            }
        }
    }
    .transparency_sec {
        .transparency_left {
            display: flex;
            align-items: center;
            height: 100%;
            flex-direction: column;
            // max-width: 450px;
            max-width: 455px;
            width: 100%;
            justify-content: center;
            margin: 0px auto;
            h2 {
                font-family: "PlusJakartaSans-Regular";
                font-size: 38px;
                line-height: 48px;
                letter-spacing: -0.02em;
                font-weight: 400;
                color: #000000;
            }
        }
        .transparency_right {
            background: #f4f9fe;
            // padding: 168px 112px;
            padding: 193px 112px;
            h2 {
                font-weight: 400;
                // font-size: 38px;
                // line-height: 48px;
                font-size: 35px;
                line-height: 42px;
                letter-spacing: -0.02em;

                font-family: "PlusJakartaSans-Regular";

                color: #242f3e;
                display: flex;
                align-items: baseline;
                img {
                    margin-right: 22px;
                    height: 18px;
                }
            }
        }
    }
    .high_quality_loan_sec {
        background: #242f3e;

        // padding-top: 80px;
        // padding-bottom: 80px;
        padding-top: 50px;
        padding-bottom: 70px;

        .high_quality_title {
            font-family: "PlusJakartaSans-Medium";
            font-size: 42px;
            line-height: 53px;
            letter-spacing: -0.02em;
            margin-top: 0px;
            color: #ffffff;
        }
        .loan_sec {
            // padding-top: 60px;

            padding-top: 6px;
        }
        .high_quality_loan_text {
            padding-right: 25px;
            h2 {
                font-family: "PlusJakartaSans-Medium";
                font-size: 28px;
                line-height: 38px;
                letter-spacing: -0.02em;
                text-align: center;
                color: #ffffff;
            }
            .high_quality_info_data {
                border: 3px solid #fff;
                padding: 20px 45px;
                min-height: 455px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;

                p {
                    font-family: "PlusJakartaSans-Regular";
                    font-size: 18px;
                    line-height: 23px;
                    letter-spacing: -0.02em;

                    color: #ffffff;
                }
            }
        }
    }
    .loan_fetures_sec {
        background: none;
        // padding-bottom: 30px;
        padding-top: 100px;
        background: none;
        padding-bottom: 101px;
        .high_quality_title {
            color: #242f3e;
        }
        .high_quality_loan_text {
            h2 {
                color: #242f3e;
            }
            .high_quality_info_data {
                border: 3px solid #242f3e;

                min-height: 350px;

                p {
                    color: #242f3e;
                }
            }
        }
    }
    .customer_facing_sec {
        // padding-top: 110px;
        // padding-bottom: 110px;
        padding-top: 165px;
        padding-bottom: 164px;
        h2 {
            font-family: "PlusJakartaSans-Bold";
            font-size: 42px;
            line-height: 54px;
            letter-spacing: -0.02em;
            color: #242f3e;
            width: 80%;
        }
        p {
            font-family: "PlusJakartaSans-Regular";
            font-size: 28px;
            line-height: 33px;
            letter-spacing: -0.02em;
            color: #242f3e;
        }
    }

    .market_condition_sec {
        margin-top: 40px;
        .market_condition_left {
            background: #242f3e;
            // padding: 190px 112px;
            // padding: 206px 112px;
            padding: 141px 112px;
            h2 {
                font-family: "PlusJakartaSans-Medium";
                font-size: 42px;
                line-height: 53px;
                font-weight: 500;
                // font-family: "PlusJakartaSans-Regular";
                // font-size: 28px;
                // line-height: 35px;
                letter-spacing: -0.02em;

                color: #ffffff;
            }
            .rate_return_sec {
                padding-top: 15px;
                p {
                    display: flex;
                    align-items: baseline;
                    font-family: "PlusJakartaSans-Regular";
                    font-size: 28px;
                    line-height: 35px;
                    letter-spacing: -0.02em;

                    color: #ffffff;
                    img {
                        margin-right: 35px;
                    }
                }
            }
        }
        .market_condition_right {
            display: flex;
            flex-direction: column;
            height: 100%;
            align-items: center;
            justify-content: center;
            background: #f4f9fe;
            h2 {
                font-family: "PlusJakartaSans-Medium";
                font-size: 42px;
                line-height: 53px;
                // text-align: center;
                letter-spacing: -0.02em;
                color: #242f3e;
                padding-left: 52px;
                padding-right: 160px;
                font-weight: 500;
                // margin-left: 45px;
                margin-left: 80px;
            }
            .bloomburg_date_sec {
                width: 534px;

                // width: 100%;
                p {
                    font-family: "PlusJakartaSans-Regular";
                    font-size: 14px;
                    line-height: 24px;
                    letter-spacing: -0.01em;
                    color: #000000;
                    font-weight: 400;
                }
            }
        }
    }
    .table_detail_sec {
        width: 75%;
        // background: #f4f9fe;
        padding: 18px 40px;
        .table_container_sec {
            table {
                .MuiTableCell-root.MuiTableCell-body {
                    font-family: "PlusJakartaSans-Regular";
                    font-size: 18px;
                    line-height: 23px;
                    letter-spacing: -0.02em;
                    border-bottom: none;
                    color: #000000;
                    padding-top: 0px;
                }
                .MuiTableCell-root.MuiTableCell-head {
                    font-family: "PlusJakartaSans-Regular";
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 23px;
                    text-align: center;
                    letter-spacing: -0.02em;
                    border-bottom: none;
                    color: #000000;
                }
            }
        }
    }
    .lenders_break_sec {
        .lenders_break_banner {
            // position: relative;
            img {
                width: 100%;
                height: 694px;
                object-fit: cover;
            }
            .lenders_break_banner_text {
                // position: absolute;
                // top: 50%;
                // left: 565px;
                // transform: translate(-50%, -50%);
                max-width: 990px;
                // padding-top: 40px;
                // padding-bottom: 40px;
                padding-top: 119px;
                padding-bottom: 118px;
                width: 100%;

                h2 {
                    font-family: "PlusJakartaSans-Bold";
                    font-size: 38px;
                    line-height: 48px;
                    color: #242f3e;
                    font-weight: 700;
                }
                p {
                    font-family: "PlusJakartaSans-Regular";
                    font-size: 28px;
                    line-height: 42px;
                    color: #242f3e;
                }
            }
        }
    }
    .expected_rate_sec {
        margin-top: 60px;
        .expected_rate_text {
            max-width: 1130px;
            width: 100%;
            h2 {
                font-family: "PlusJakartaSans-Bold";
                font-size: 38px;
                line-height: 50px;
                letter-spacing: -0.02em;
                color: #242f3e;
            }
            h3 {
                font-family: "PlusJakartaSans-Bold";
                font-size: 38px;
                line-height: 50px;
                letter-spacing: -0.02em;
                color: #242f3e;
                margin-top: 35px;
            }
            p {
                font-family: "PlusJakartaSans-Light";
                font-size: 32px;
                line-height: 38px;
                letter-spacing: -0.02em;
                color: #242f3e;
            }
        }
    }
    .borrower_characteristics_sec {
        background: #242f3e;
        padding-top: 60px;
        padding-bottom: 60px;
        .borrower_text_sec {
            width: 73%;
            h2 {
                font-family: "PlusJakartaSans-Bold";
                font-size: 42px;
                line-height: 53px;
                letter-spacing: -0.02em;
                color: #ffffff;
            }
            p {
                font-family: "PlusJakartaSans-Light";
                font-size: 25px;
                line-height: 32px;
                letter-spacing: -0.02em;
                color: #ffffff;
            }
        }
    }
}
// lenders landing page code end

// modal code start
.success_modal_sec {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.success_msg_sec {
    background: #fff;
    outline: none;
    border: none;
    border-top: 10px solid #1a7cfa;
    padding: 75px 45px;

    text-align: center;
    width: 540px;
    position: relative;
    p {
        font-family: "PlusJakartaSans-SemiBold";
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        color: #242f3e;
        margin-top: 40px;
    }
    .modal_close_icon {
        position: absolute;
        right: 44px;
        top: 28px;
        height: 13px;
        cursor: pointer;
    }
}

.modal_main_sec {
    overflow-y: auto;
    min-height: 100vh;
}
.modal_content {
    background: #fff;
    width: 700px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    padding-top: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 55px;
    position: relative;
}
.modal_sec {
    margin: 0px auto;
    min-height: 100vh;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    .close_icon {
        position: absolute;
        right: 44px;
        top: 28px;
        height: 13px;
        cursor: pointer;
    }
    .modal_form_list {
        width: 430px;
        .MuiFormControl-root {
            margin-top: 30px;
        }
        .MuiOutlinedInput-notchedOutline {
            border: 1px solid #000;
        }
        ::placeholder {
            font-family: "PlusJakartaSans-Regular";
            font-size: 12px;
            line-height: 15px;
            color: #242f3e;
        }
    }
    h2 {
        font-family: "PlusJakartaSans-Bold";
        font-size: 20px;
        line-height: 25px;
        color: #242f3e;
    }
    .submit_btn {
        background: #1a7cfa;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0605059);
        border-radius: 2px;
        padding: 15px 10px;
        margin-top: 30px;
        width: 100%;
        span {
            font-family: "PlusJakartaSans-Medium";
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            letter-spacing: 0.8px;
            color: #ffffff;
            opacity: 0.9;
        }
        &:hover {
            background: #1a7cfa;
        }
    }
}
// modal code end

.heading_tag_line {
    p {
        font-family: "PlusJakartaSans-Bold";
        font-size: 36px;
        line-height: 45px;
        color: #1a7cfa;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

// **New Header Section
.headerPadding {
    .MuiToolbar-regular {
        min-height: 56px !important;
    }
    .MuiToolbar-gutters {
        padding: 0px 0px;
    }
    display: initial;
}
.headerPadding.MuiAppBar-root {
    display: initial;
}
.MuiAppBar-colorPrimary.headerPadding {
    background-color: #fff;
}
.header_max_width {
    min-width: 1360px;
    max-width: 1360px;
    padding-left: 60px;
    padding-right: 60px;
    margin: auto;
}
.headerSec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    height: 56px !important;
}
.headerNav {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.headerLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 40px;
    p {
        font-weight: 500;
        font-size: 14px;
        letter-spacing: -0.02em;
        color: #242f3e;
        font-family: "PlusJakartaSans-Medium";
        margin: 0px 15px;
        cursor: pointer;
        a {
            font-weight: 500;
            font-size: 14px;
            letter-spacing: -0.02em;
            color: #242f3e;
            font-family: "PlusJakartaSans-Medium";
            cursor: pointer;
            text-decoration: none;
        }
    }
}
.headerRight {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .MuiButton-containedPrimary {
        background-color: $jumna-blue;
        color: $white;
    }
    .MuiButton-label {
        font-family: "PlusJakartaSans-Medium";
        font-weight: 500;
        font-size: 13px;
        letter-spacing: -0.02em;
        text-transform: none;
    }
    .MuiButton-outlinedPrimary {
        color: $jumna-blue;
        border: 0.6px solid $jumna-blue;
        &:hover {
            border: 0.6px solid $jumna-blue;
        }
    }
    .MuiButton-root {
        border-radius: 2px;
    }
    .MuiButton-containedPrimary:hover {
        background-color: $jumna-blue;
    }
}
.headerWidth {
    width: 50%;
}
.btnWidth {
    width: 100px;
}
.margin_bottom_40 {
    margin-bottom: 40px;
}
.margin_left_10 {
    margin-left: 10px;
}
.margin_rigth_20 {
    margin-right: 20px;
}
.closeImg {
    float: right;
}
.unplank_logo {
    width: 172px;
    cursor: pointer;
}
.signUpContainer {
    display: flex;
    //flex-direction: column;
    //align-items: center;
    justify-content: center;
    background-color: #f4f4f4;
    width: 100%;
    height: 100vh;
    overflow: auto;
}
.cardContainer {
    background-color: $white;
    width: 70%;
    height: 563px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px auto;
    padding: 40px;
    box-sizing: border-box;
    margin-top: 130px;
}
.decImgWidth {
    width: 500px;
}
.decTextWrapper {
    background: rgba(187, 29, 29, 0.03);
    display: flex;
    padding: 0px 40px;
    margin-top: 30px;
    align-items: center;
    height: 47px;
}
.verifyTextWrapper {
    background: rgba(26, 124, 250, 0.1);
    display: flex;
    padding: 0px 40px;
    margin-top: 30px;
    align-items: center;
    height: 47px;
}
.infoImgWidth {
    width: 20px;
    height: 20px;
    margin-right: 12px;
}
.declineTxt {
    font-size: 16px;
    letter-spacing: 0.7px;
    color: #000000;
    font-family: $plusJakartaSans-SemiBold;
}
.adminDetailsSec {
    display: flex;
    flex-direction: row;
}
// .max-width.banner_content {
//     padding: 0px 0px;
// }

// privacy policy code start
.privacy_policy_sec {
    padding-bottom: 30px;
}
.privacy_heading {
    text-align: center;
    h2 {
        font-family: "PlusJakartaSans-Bold";
        font-size: 48px;
        line-height: 60px;
        color: #242f3e;
    }
}
.last_update_title {
    h2 {
        font-family: "PlusJakartaSans-Regular";
        font-size: 18px;
        line-height: 48px;
        letter-spacing: -0.02em;
        color: #242f3e;
        opacity: 0.8;
        font-weight: 400;
    }
}
.last_update_title_tc {
    h2 {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.7px;
        color: #282828;
        font-family: $plusJakartaSans-Regular;
        opacity: 0.8;
        font-weight: 400;
    }
}
.policy_detail_sec {
    p {
        font-family: "PlusJakartaSans-Regular";
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: #242f3e;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}
.policy_detail_sec_tc {
    p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.7px;
        color: #282828;
        font-family: $plusJakartaSans-Regular;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}
.link_text {
    color: #1a7cfa;
    text-decoration: underline;
    cursor: pointer;
}
.sub_link_text {
    padding-left: 10px;
}
.pl_20 {
    padding-left: 24px;
}
.privacy_policy_info {
    h2 {
        font-family: "PlusJakartaSans-SemiBold";
        font-weight: 600;
        font-size: 28px;
        line-height: 38px;
        letter-spacing: -0.02em;
        color: #242f3e;
        margin-bottom: 0px;
    }
    p {
        font-family: "PlusJakartaSans-Regular";
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: #242f3e;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}
.privacy_policy_info_tc {
    h2 {
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: 0.7px;
        color: #282828;
        font-family: $plusJakartaSans-Bold;
        margin-bottom: 0px;
    }
    p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.7px;
        color: #282828;
        font-family: $plusJakartaSans-Regular;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}
// privacy policy code end

.headerLeft .header_active {
    font-family: "PlusJakartaSans-Bold";
    position: relative;
    &::after {
        content: "";
        position: absolute;
        border-bottom: 2px solid #1a7cfa;
        top: 22px;
        width: 100%;
        left: 0px;
    }
}
.menuNavItems .mobileMenuItem.header_active {
    font-family: "PlusJakartaSans-Bold";
}

.MuiRadio-colorPrimary.Mui-checked {
    color: #1a7cfa;
    span {
        color: #1a7cfa;
    }
}
.signInRadio {
    //margin-left: 25px;
    span {
        font-family: "PlusJakartaSans-SemiBold";
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.02em;
    }
}
.radio_btn_sec span {
    font-family: "PlusJakartaSans-SemiBold";
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #1a7cfa;
}
.MuiFormControlLabel-root {
    font-family: "PlusJakartaSans-Regular";
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;

    color: rgba(51, 63, 72, 0.8);
}
.input_sec {
    margin-top: 35px;
}
.sign_up_sec .personalMobSec {
    .MuiInputBase-formControl {
        max-width: 370px;
        width: 100%;
    }
}
.personalMobSec {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .MuiFormLabel-root.Mui-focused {
        color: #1a7cfa;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #1a7cfa;
    }
}
.mobCodeWrapper {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border: 1px solid #242F3E;
    border-radius: 2px;
    margin-right: 8px;
    height: 56px;
}
.errorMssgMobile {
    margin: -14px 0px 10px 62px;
    font-size: 14px;
    line-height: 23px;
    color: #EB5757;
}
// faq section start
.faq_section {
    margin-top: 40px;
}
.faq_papper_sec {
    margin-top: 20px;
    padding: 30px 35px;
    padding-bottom: 50px;
}
.faq_detail_sec {
    width: 92%;
    margin: auto;
}
.faq_detail_sec {
    h2 {
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #000000;
        padding-left: 10px;
    }
    h3 {
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.5px;
        color: #000000;
    }
}
.faq_accordion_sec {
    margin-top: 35px;
    .pannel_header {
        background: #f4f9ff;
        margin-top: 20px;
        min-height: 57px;
        border-top: 0.5px solid #d2d2d2;
        border-bottom: 0.5px solid #d2d2d2;
    }
    .MuiAccordionDetails-root {
        padding-bottom: 0px;
    }
}
.faq_accordion_sec .MuiAccordionSummary-root.Mui-expanded {
    min-height: 57px;
}
.faq_accordion_sec .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
}
.Mui-expanded {
    box-shadow: none;
    border: none;
}
.faq_detail_sec .MuiPaper-elevation1 {
    box-shadow: none;
    border: none;
}
.faq_accordion_sec .MuiAccordion-root::before {
    background-color: none;
    top: 0px;
}
.MuiTypography-body1.faq_question_title {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.5px;
    color: #1a7cfa;
    font-family: $plusJakartaSans-Bold;
    padding: 0px 20px;
}
// .faq_question_title {
//     font-weight: 700;
//     font-size: 14px;
//     line-height: 19px;
//     letter-spacing: 0.5px;
//     color: #1a7cfa;
//     font-family: "Notosans-regular";
// }
.MuiTypography-body1.faq_answer {
    font-family: $plusJakartaSans-Regular;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.5px;
    color: #0e0e0e;
    padding: 0px 20px;
    margin-top: 10px;
}
.faq_detail_headings {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.5px;
    color: #000000;
    margin-top: 50px;
    margin-bottom: 50px;
}
// faq section end
//side nav section
.sideNavbar {
    div {
        div:nth-last-child(2) {
            display: none !important;
        }
        div:nth-last-child(3) {
            display: none !important;
        }
    }
}
.customerImg {
    width: 15px;
    margin-left: 10px;
}
.cutomerSidenav {
    display: flex;
    align-items: center;
}
.cutomerSidenavImg {
    width: 22px;
    height: 22px;
    margin-left: 10px;
}
// costumer aquistion sec
.rewardsHeadSec {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    .MuiTypography-body1 {
        font-family: $plusJakartaSans-Bold;
        font-size: 18px;
        color: $black;
    }
}
.premiumText {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.7px;
    color: #333333;
    opacity: 0.9;
}
.VerifyAccountHeading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    text-align: center;
    .MuiTypography-body1 {
        font-size: 21px;
        line-height: 28px;
        letter-spacing: 0.5px;
        color: #000000;
        font-family: $plusJakartaSans-Bold;
        margin-right: 10px;
    }
}
.accountQuestionsSec {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
}
.commingSoonSec {
    background-color: rgba(26,124,250,0.1);
    display: flex;
    justify-content: space-between;
    padding: 0px 32px 0px 16px;
    position: relative;
    line-height: 14px;
    img {
        margin-right: 6px;
    }
    p{
        font-size: 12px;
        color: $jumna-blue;
        letter-spacing: 0.5px;
        font-family: $plusJakartaSans-Bold;
    }
    &::after {
        content: "";
        position: absolute;
        //border: solid rgba(38, 138, 255, 0.1);
        border: 50px solid transparent;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 14px;
        top: 3px;
        right: -20px;
        background: #fff;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
    }
}
.video_popup_sec {
    .MuiDialog-paperWidthSm {
        max-width: 700px;
    }
    .MuiDialogContent-root {
        padding: 0px;
    }
    .MuiDialogContent-root:first-child {
        padding-top: 0px;
    }
    .MuiPaper-root {
        background-color: rgba(255,255,255,0.6);
    }
}
.join_popup_sec {
    .MuiDialog-paperWidthSm {
        max-width: 500px;
    }
    .MuiDialog-paper {
        padding: 20px;
    }
    .MuiDialogTitle-root {
        padding: 16px 24px 0px;
    }
    .MuiDialogContent-root {
        padding: 20px 60px;
    }
    .demo_form_list {
        //width: 430px;
        .MuiInputLabel-outlined {
            font-size: 14px;
            font-family: $plusJakartaSans-Regular;
            color: #242F3E;
            line-height: 18px;
        }
        .MuiFormControl-root {
            margin-top: 30px;
        }
        .MuiOutlinedInput-notchedOutline {
            border: 1px solid #000;
        }
        // ::placeholder {
        //     font-family: "PlusJakartaSans-Regular";
        //     font-size: 12px;
        //     line-height: 15px;
        //     color: #242f3e;
        // }
    }
    .MuiTypography-h6 {
        font-size: 28px;
        line-height: 35px;
        letter-spacing: 0.01em;
        text-align: center;
        color: #242F3E;
        font-family: $plusJakartaSans-Bold;
    }
}
.demo_popup_sec {
    .MuiDialog-paperWidthSm {
        max-width: 660px;
    }
    .MuiDialog-paper {
        padding: 20px;
    }
    .MuiDialogTitle-root {
        padding: 16px 24px 0px;
    }
    .MuiDialogContent-root {
        padding: 0px 120px;
    }
    .demo_form_list {
        //width: 430px;
        .MuiInputLabel-outlined {
            font-size: 14px;
            font-family: $plusJakartaSans-Regular;
            color: #242F3E;
            line-height: 18px;
        }
        .MuiFormControl-root {
            margin-top: 30px;
        }
        .MuiOutlinedInput-notchedOutline {
            border: 1px solid #000;
        }
        // ::placeholder {
        //     font-family: "PlusJakartaSans-Regular";
        //     font-size: 12px;
        //     line-height: 15px;
        //     color: #242f3e;
        // }
    }
    .MuiTypography-h6 {
        font-size: 28px;
        line-height: 35px;
        letter-spacing: 0.01em;
        text-align: center;
        color: $black;
        font-family: $plusJakartaSans-Bold;
    }
    .submit_btn {
        background: $jumna-blue;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0605059);
        border-radius: 2px;
        padding: 15px 10px;
        margin-top: 30px;
        width: 100%;
        span {
            font-family: $plusJakartaSans-Medium;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            letter-spacing: 0.8px;
            color: #ffffff;
            opacity: 0.9;
        }
        &:hover {
            background: $jumna-blue;
        }
    }
    .MuiFormLabel-root.Mui-focused {
        color: #1a7cfa;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #1a7cfa;
    }
}
.BannerVideo {
    width: 100%;
    height: 500px;
}
.bank_popup_sec{
    .MuiDialog-paperWidthSm {
        max-width: 800px;
    }
    .MuiDialog-paper {
        padding: 20px;
    }
}
.accountQuestion {
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    font-family: $plusJakartaSans-Bold;
    margin-bottom: 5px;
}
.accountAnswere {
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    font-family: $plusJakartaSans-Regular;
    // margin-bottom: 10px;
    margin-bottom: 20px;
}
.accountAnswereList {
    margin-top: 0px;
    margin-bottom: 0px;
}
.linkDialogFooter {
    text-align: center;
    margin-top: 20px;
    .MuiTypography-body1 {
        font-size: 14px;
        line-height: 22px;
        color: #000000;
        font-family: $plusJakartaSans-SemiBold;
    }
}
.directTxt {
    color: $jumna-blue;
    font-size: 14px;
    font-family: $plusJakartaSans-Bold;
    margin-left: 4px;
    //cursor: initial;
}
.promotionWrapper,
.discounts_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
    margin-bottom: 20px;
    .MuiInput-underline:hover:not(.Mui-disabled):before,
    .MuiInput-underline:after {
        border-bottom: 2px solid $jumna-blue;
    }
    .MuiFormLabel-root.Mui-focused {
        color: $jumna-blue;
    }
}
.discounts_popup {
    padding: 30px;
}
.linkDevicePopUp {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 2rem;
    .MuiButton-root {
        text-transform: none;
    }
    .MuiDialogActions-root {
        padding: 16px;
    }
}
// create storefont start
.createStoreSec{
    .createStoreHeading{
        h2{
            font-family: $plusJakartaSans-Regular;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #000000;
            // margin-bottom: 0px;
            span{
                font-family: $plusJakartaSans-Bold;
            }
        }
        p{
            font-family: $plusJakartaSans-Bold;
            font-size: 14px;
            line-height: 24px;
            color: #000000;
            margin-left: 25px;
            margin-top: 8px;
            margin-bottom: 8px;
        }
        .createStoreDetails{
            padding-left: 55px;
            margin-top: 0px;
            margin-bottom: 0px;
            li{
                font-family: $plusJakartaSans-Regular;
            font-size: 14px;
            line-height: 24px;
            color: #000000;
            }
        }
    }
}
// create storefont end
//activate merchant
.manageStoreBodyHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 30px;
    margin: 0px 20px;
    .MuiTouchRipple-root {
        display: none;
    }
}
.imagePreviewWrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: hidden;
    background-color: transparent;
    position: absolute;
    margin-top: -270px;
    margin-left: 58px;
}
.storeimagePreviewWrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: hidden;
    background-color: transparent;
    position: absolute;
    margin-top: -290px;
    margin-left: 58px;
}
.cloudImg {
    height: 30px;
}
@media screen and (max-width: 767px) {
    // new home screen UI start
    .attract_last_img{
        margin-left: 0px;
    }
    .home_banner {
        display: flex;
        flex-direction: column-reverse;
        height: auto;
        img {
            height: auto;
        }
    }
    .banner_signup {
        width: auto;
        height: auto;
        position: unset;
        padding: 50px 20px 20px;
        h2 {
            font-size: 46px;
            line-height: 58px;
        }
        h4 {
            font-size: 18px;
            line-height: 23px;
            margin-block-start: 18px;
            margin-block-end: 6px;
        }
        .bannerSubText {
            font-size: 16px;
            line-height: 20px;
            margin-block-end: 45px;
        }
        .MuiButton-root {
            width: 100%;
            height: 48px;
        }
        .faqLink {
            font-size: 16px;
            line-height: 20px;
            margin-top: 24px;
            margin-bottom: 37px;
        }
        .joinPilotBtn {
            h3 {
                font-size: 16px;
            }
        }
    }
    .app_to_app_sec {
        .grid_top {
            margin-top: 10px;
        }
        .app_to_app_content {
            margin-top: 30px;
            h2{
                font-size: 28px;
                line-height: 35px;
                margin-block-end: 25px;
            }
            p{
                font-size: 16px;
                line-height: 20px;
            }
            h4 {
                font-size: 18px;
                line-height: 23px;
            }
        }
        button {
            width: 100%;
            height: 48px;
            margin-top: 60px;
        }
        img {
            height: 50vh;
            width: 100%;
            margin-top: 50px;
        }
        .MuiButton-label {
            font-size: 16px;
        }
    }
    .mob_image {
        width: 100%;
        height: auto;
    }
    .steps_sec {
        flex-direction: column;
        img {
            width: 100%;
        }
        .five_easy_steps {
            width: 100%;
        }
    }
    .five_easy_steps_sec {
        padding: 80px 0px;
    }
    .nochangeSec {
        padding-top: 44px;
        padding-bottom: 20px;
    }
    .payment_sec {
        padding-top: 40px;
        padding-bottom: 0px;
    }
    .gradientMain {
        margin-top: 30px;
        margin-bottom: 0px;
    }
    .payment_method_sec {
        flex-direction: column-reverse;
    }
    .payment_method_content {
        width: 100%;
        margin: auto;
        padding: 20px 0px 50px;
        h2 {
            font-size: 28px;
            line-height: 35px;
            margin-block-end: 25px
        }
        h4 {
            font-size: 18px;
            line-height: 23px;
        }
        p {
            font-size: 16px;
            line-height: 20px;
        }
    }
    .gradient_sec {
        padding: 20px;
        h2 {
            font-size: 28px;
            line-height: 35px;
            margin-block-start: 40px;
        }
        img {
            margin-left: 0px;
            width: 100%;
        }
        .gradient_content {
            padding: 10px 0px;
            .beatText {
                font-size: 22px;
                line-height: 28px;
                margin-top: 60px;
                margin-bottom: 18px;
            }
            p {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
    .five_easy_steps {
        padding: 10px 0px;
        h2 {
            font-size: 30px;
            line-height: 38px;
        }
        p {
            font-size: 16px;
            line-height: 20px;
        }
        .setUpText {
            margin-bottom: 5px;
            span {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
    .chat_with_cust_sec {
        flex-direction: column-reverse;
        .chat_text {
            padding-left: 0px;
            h2 {
                font-size: 27px;
                line-height: 35px;
                margin-top: 40px;
                margin-bottom: 25px;
            }
            h4 {
                font-size: 18px;
                line-height: 23px;
            }
            p {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
    .business_content {
        .tick_sec {
            padding-top: 30px;
            width: 100%;
        }
        .tick_content {
            span{
                font-size: 22px;
                line-height: 28px;
            }
        }
        p {
            font-size: 16px;
            line-height: 20px;
            margin-left: 14px;
            padding-left: 30px;
        }
        .tick_spacing {
            padding-bottom: 25px;
        }
    }
    .attract_sec {
        padding-top: 10px;
        padding-bottom: 58px;
    }
    .key_sec {
        padding-top: 20px;
    }
    .data_privacy_sec {
        flex-direction: column-reverse;
        gap: 0rem;
        img {
            margin-right: 0px;
            width: 100%;
        }
        .data_privacy_text {
            margin-left: 0px;
            margin-top: 50px;
            .MuiTypography-h1 {
                font-size: 28px;
                line-height: 35px;
                margin-block-end: 25px;
            }
            h2 {
                font-size: 18px;
                line-height: 23px;
            }
            p {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
    .know_more_sec {
        padding: 30px 10px;
        .attract_sub_text {
            font-size: 18px;
            line-height: 23px;
            //padding-top: 10px;
        }
        h2 {
            font-size: 28px;
            line-height: 35px;
            margin-block-end: 25px
        }
        p {
            font-size: 16px;
            line-height: 20px;
        }
        .MuiButton-contained:hover,
        .MuiButton-contained{
            background-color: $jumna-blue;
            color: $white;
            width: 100%;
            height: 48px;
            margin-top: 58px;
        }
        .MuiButton-root {
            font-size: 16px;
        }
    }
    // new home screen UI end
    // shop with unplank UI start
    .shopUnplank_banner_sec {
        height: auto;
        img {
            height: auto;
            object-fit: contain;
        }
        .shopUnplank_banner_text {
            padding-top: 30px;
            padding-left: 20px;
            h2 {
                font-size: 34px;
                line-height: 43px;
            }
            h4 {
                font-size: 28px;
                line-height: 35px;
            }
            p {
                font-size: 18px;
                line-height: 23px;
                margin-bottom: 26px;
            }
            .MuiButton-contained:hover,
            .MuiButton-contained{
                background-color: $jumna-blue;
                color: $white;
                width: 100%;
            }
        }
    }
    .credit_sec {
        margin: 40px auto;
        width: 100%;
    }
    .credit_type_sec {
        border-radius: 0px;
        padding: 20px;
        p{
            font-size: 18px;
            line-height: 33px;
            margin-block-start: 16px;
            margin-block-end: 16px;
        }
    }
    .credit_content {
        padding-top: 40px;
        padding-right: 20px;
        padding-left: 20px;
        padding-bottom: 40px;
        h2 {
            font-size: 26px;
            line-height: 33px;
            margin-block-start: 0em;
        }
        p {
            font-size: 16px;
            line-height: 26px;
        }
    }
    .transact_ease_sec {
        flex-direction: column-reverse;
        margin-top: 32px;
        //margin-bottom: -17px;
        gap: 1em;
        img {
            width: 100%;
            // margin-left: -10px;
            // margin-right: -10px;
        }
    }
    .transact_ease_content {
        padding: 20px;
        h2 {
            font-size: 26px;
            line-height: 33px;
        }
        p {
            font-size: 16px;
            line-height: 28px;
        }
    }
    // shop with unplank UI end
    .social_links_sec {
        padding-left: 0px;
        margin-top: 10px;
        flex-direction: row;
        align-items: center;
        p{
            margin-right: 20px;
            font-size: 16px;
        }
        img {
            width: 24px;
            height: 24px;
            margin-right: 14px;
        }
    }
    //link Direct Id pop up
    .linkTypes {
        margin-left: 0px;
    }
    .linkDirectIDPopUp {
        .MuiTypography-body1 {
            font-size: 16px;
        }
    }
    .linkedAccountHeader {
        .MuiTypography-body1 {
            width: 38%;
            word-wrap: break-word;
        }
    }
    //home recapcha
    .reCapchaHome {
        margin-left: 0px;
    }
    .errorMssgMobile {
        font-size: 12px;
    }
    //home banner create account sec
    .bannerWrapper {
        flex-direction: column;
        width: 100%;
    }
    .expressTxt {
        margin-bottom: 12px;
        padding-top: 0px;
    }
    .homeBannerSec {
        justify-content: space-evenly;
        padding-bottom: 24px;
        width: 100%;
    }
    .homeBannerContent {
        width: 161px;
        height: 39px;
        flex-direction: row;
        margin-right: 0px;
        .cursorImg {
            width: 20px;
            margin-right: 6px;
        }
        .videoImg {
            width: 18px;
            margin-right: 6px;
            margin-top: 2px;
        }
        .MuiTypography-body1 {
            font-size: 13px;
        }
    }
    .createAccountSec {
        //width: 100%;
        padding: 24px 60px 0px;
        //text-align: center;
    }
    .demo_popup_sec {
        .MuiDialogContent-root {
            padding: 0px;
        }
        .submit_btn {
            margin-top: 10px;
        }
    }
    .join_popup_sec {
        .MuiDialogContent-root {
            padding: 20px 10px;
        }
    }
    .reCapchaJoin,
    .reCapchaSecDemo {
        margin: 20px 16px 0px;
        justify-content: unset;
        transform:scale(0.77);
        -webkit-transform:scale(0.77);
        transform-origin:0 0;
        -webkit-transform-origin:0 0;
    }
    .BannerVideo {
        width: 100%;
        height: 300px;
    }
    .video_popup_sec {
        .MuiDialogContent-root {
            padding: 0px;
        }
    }
    //link account sec
    .linkedMultiAccountMain {
        padding: 30px 20px;
    }
    .linkedAccountWrapper {
        flex-direction: column;
    }
    .accountSecBank {
        width: auto;
    }
    .bankDetailsWrapper {
        padding: 30px 0px 30px;
    }
    .accountDetailsSec {
        margin-right: 0px;
    }
    .linkBankPopUp {
        .MuiDialogContent-root {
            padding: 15px 30px;
        }
    }
    .browseSec {
        flex-direction: column;
    }
    .cloudImg {
        width: 15px;
        height: 15px;
    }
    .dragWrapper {
        justify-content: flex-end;
    }
    .dragAndDropSec {
        .filepond--root :not(text) {
            font-size: 12px !important;
        }
    }
    //transaction record
    .transactionTable {
        padding: 0px;
    }
    .tableWidth { 
        .MuiTableCell-root {
            min-width: 70px;
            padding: 10px;
            font-size: 12px;
        }  
    }
    .headerText {
        font-size: 14px;
    }
    .textBold {
        .MuiTableCell-head {
            font-size: 13px;
        }
    }
    .image_preview_wrapper {
        margin-left: 0px;
    }
    //otp sec
    .otpSec {
        .MuiFormControl-root {
            width: 35px !important;
            margin-left: 5px;
            margin-right: 5px;
        }
    }
    //user device popup
    .fieldsContainer {
        padding: 0px 10px 20px;
    }
    //add store 
    .forHeaderText {
        margin: 0px auto;
        .MuiTypography-body1 {
            font-size: 14px;
        }
    }
    .storeRadioGroup {
        .MuiTypography-body1 {
            font-family: $plusJakartaSans-Regular;
            font-size: 12px !important;
        }
    }
    .dragTxt {
        display: none;
    }
    .fileUploadImg {
        padding: 0rem;
    }
    .uploadSec {
        gap: 0rem;
    }
    .storeUploadSec {
        .MuiTypography-body1 {
            font-size: 12px !important;
        }
    }
    //rewards mob footer
    .enable_premium_mob {
        background: #F4F9FE;
        border-radius: 0px 0px 3px 3px;
        text-align: center;
        padding: 6px;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .premiumText {
        font-size: 11px;
        font-family: $plusJakartaSans-Regular;
        line-height: 13px;
        letter-spacing: 0.828235px; 
        color: #242F3E;
        opacity: 1;
        .MuiCheckbox-colorSecondary.Mui-checked {
            color: $jumna-blue;
        }
    }
    .signUpContainer {
        background-color: $white;
    }
    //boarding header img
    .boardingImg {
        padding: 0px 10px;
    }
    //account pop up
    .VerifyAccountHeading {
        flex-direction: column;
        padding: 14px 0px;
    }
    .accountQuestionsSec {
        padding: 0px;
    }
    .commingSoonSec {
        margin-top: 10px;
    }
    .accountAnswere {
        font-size: 12px;
    }
    .linkDialogFooter {
        .MuiTypography-body1 {
            font-size: 10px;
        }
    }
    .bank_popup_sec{
        .MuiDialog-paper {
            padding: 10px;
        }
    }
    //activate merchant
    .manageStoreBodyHeader {
        padding: 1rem 10px;
        margin: 0px 10px;
    }
    //pop up screens
    .discounts_popup {
        padding: 0px;
    }
    .promoTxt {
        p {
            font-size: 12px;
            line-height: 24px;
        }
    }
    .promoSec {
        p {
            font-size: 10px;
        }
    }
    .promoBtn {
        .MuiButton-containedPrimary {
            font-size: 12px;
        }
        .MuiButton-outlinedPrimary {
            font-size: 12px;
        }
    }
    .conformPopUp {
        .MuiDialogTitle-root {
            padding: 20px;
        }
        .MuiDialogActions-root {
            padding: 20px;
        }
        .MuiTypography-h6 {
            font-size: 18px;
        }
        .MuiButton-outlinedPrimary,
        .MuiButton-containedPrimary {
            font-size: 14px;
        }
    }
    //store pop up screens
    .storePopUps {
        .MuiDialog-paperWidthSm {
            width: 100%;
        }
    }
    .linkDevicePopUp {
        padding: 0rem;
        .MuiButton-root {
            padding: 6px;
            font-size: 12px;
            text-transform: none;
        }
        .MuiDialogActions-root {
            padding: 8px;
        }
    }
    .updateDevicePopUp {
        padding: 20px;
    }
    //imagepreview sec
    .image_preview_wrapper {
        margin-top: 10px;
    }
    //store fileupload sec
    .cloudImg {
        width: 20px;
    }
    .dragWrapperDiscount,
    .dragWrapper {
        justify-content: flex-end;
    }
    //discounts fileupload sec
    .fileUploadSec {
        flex-direction: column;
        align-items: flex-start;
    }
    .fileUploadImgStore {
        padding: 0px;
    }
    .fileUploadWrapper {
        width: 300px;
        gap: 9px;
           .MuiButton-outlined {
                padding: 6px 2px;
                font-size: 12px;
           }
    }
    .changeSec {
        gap: 0rem;
        .MuiTypography-body1 {
            margin-left: 0px !important;
            overflow: hidden;
            width: 150px;
            text-overflow: ellipsis;
            // display: -webkit-box;
            // -webkit-line-clamp: 2;
            // -webkit-box-orient: vertical;
        }
    }
    //admin sec
    .admin_wid_sec button.MuiTab-textColorPrimary{
        // width: 40%;
        // margin: 0px !important;
        width: 50%;
        margin: 0px auto;
    }
    .admin_wrapper .MuiTabs-flexContainer {
        justify-content: left;
    }
    .admin_wid_sec {
        margin: 0px auto !important;
        .MuiTab-wrapper {
            font-size: 12px !important;
        }
    }
    // discounts image sec
    .discountText {
        top: 104px;
    }
    //register account
    .registerFormTxt {
        label + .MuiInput-formControl {
            margin-bottom: 0px;
        }
    }
    //admin responsive
    .adminDetailsSec {
        flex-direction: column;
    }
    .details_mob_row {
        flex-direction: row;
    }
    .businessColumn {
        flex-basis: 50%;
    }
    .detailsSec {
        padding: 10px;
    }
    .marginLeft {
        margin-left: 20px;
    }
    .margin_mob {
        margin-left: 20px;
    }
    .margin_top_10 {
        margin-top: 10px;
    }
    .accontStatusMobile {
        width: "100%";
        height: 59px;
        background: #F4F9FF;
        padding: 0px 10px;
        box-sizing: border-box;
    }
    .mobile_status_container {
        display: flex;
        flex-direction: column;
        img {
            width: 15px;
            height: 15px;
        }
        a {
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            color: #eb5757;
            font-family: $plusJakartaSans-Regular;
        }
    }
    .accTxt {
        font-size: 13px;
        line-height: 19px;
        opacity: 0.8;
        color: #333f48;
        font-family: $plusJakartaSans-Bold;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .pendingTxt {
        font-size: 14px;
        line-height: 19px;
        color: $black;
        opacity: 0.8;
        font-family: $plusJakartaSans-Bold;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .accountSec {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    // .scrollContainer {
    //     white-space: nowrap;
    //     overflow-y: scroll;
    //     a {
    //         font-size: 12px;
    //     }
    //     p {
    //         font-size: 12px;
    //     }
    // }
    .mob-bg {
        background-color: rgb(244, 244, 244);
    }
    //decline and verify user
    .cardContainer {
        width: 94%;
        padding: 30px;
    }
    .decImgWidth {
        width: 200px;
    }
    .decTextWrapper,
    .verifyTextWrapper {
        padding: 0px 16px;
    }
    .declineTxt {
        font-size: 10px;
    }
    //t and c sec
    .tandcBox {
        height: auto;
        background: #ffffff;
        border: none;
        margin: 20px auto;
        padding: 10px;
    }
    .max-width.banner_content {
        padding: 0 20px;
    }
    .footer_left_sec {
        img {
            width: 124px;
        }
    }
    //header section
    .headerPadding {
        .MuiToolbar-gutters {
            padding: 0px 20px !important;
        }
    }
    //landing page styles
    .marquee_wrapper {
        display: none;
    }
    .banner_image {
        //margin-top: -22px;
        .mobileBanner {
            display: block;
        }
        .webBanner {
            display: none;
        }
        .mobile-banner {
            display: block;
        }
        .bannerText {
            color: white;
            text-transform: uppercase;
            margin: 0;
            font-size: 18px;
        }
        .bannerHead {
            font-size: 42px;
        }
        .transText {
            margin-top: 10px;
            margin-bottom: 30px;
        }
        .bannerButtons {
            .MuiButton-root {
                display: block;
                width: 100%;
                padding: 10px;
            }
            .createBtn {
                background: white;
                margin-bottom: 18px;
                .MuiButton-label {
                    font-family: $plusJakartaSans-Bold;
                    text-transform: none;
                    color: #1a7cfa;
                }
            }
            .signinBtn {
                border: 1px solid white;
                .MuiButton-label {
                    font-family: $plusJakartaSans-Bold;
                    text-transform: none;
                    color: white;
                }
            }
        }
    }
    .section_one_text {
        flex: 0 0 100%;
        padding: 0 20px;
        box-sizing: border-box;
    }
    .section_two_text {
        flex: 0 0 100%;
        padding: 0 20px;
        box-sizing: border-box;
    }
    .section_three_text {
        flex: 0 0 100%;
        padding: 0 20px;
        box-sizing: border-box;
    }
    .section_four_text {
        flex: 0 0 100%;
        padding: 0 20px;
        box-sizing: border-box;
    }
    .section_one_image {
        flex: 0 0 100%;
    }
    .section_two_image {
        flex: 0 0 100%;
    }
    .section_three_image {
        flex: 0 0 100%;
    }
    .section_four_image {
        flex: 0 0 100%;
    }
    .mobileSectionOne {
        display: block;
        margin-bottom: 10px;
        margin-right: -20px;
    }
    .webSectionOne {
        display: none;
    }
    .footer_content {
        display: block;
    }
    .navList {
        justify-content: space-between;
        margin-top: 25px;
        p {
            margin: 0;
        }
    }

    //header styles
    .webHeader {
        display: none;
    }
    .mobileHeader {
        display: block;
        .menuOverlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 240px;
            height: 100vh;
            background-color: $jumna-blue;
            z-index: 2;
            &::after {
                background: rgba(255, 255, 255, 0.4);
                position: fixed;
                content: "";
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: -1;
                width: calc(100% - 240px);
            }
        }
        .menuNavItems {
            padding: 20px 10px;
        }
        .mobileMenuItem {
            margin: 30px 20px;
            color: $white;
            font-weight: 500;
            font-size: 18px;
            line-height: 23px;
            font-family: "PlusJakartaSans-Regular";
            a {
                color: $white;
                font-weight: 500;
                font-size: 18px;
                line-height: 23px;
                font-family: "PlusJakartaSans-Regular";
                text-decoration: none;
            }
        }
    }

    //Dashboard main styles
    .dashboardMain {
        margin-left: 0;
        padding-bottom: 0px;
        background: #fff;
        overflow-x: hidden;
    }

    //side Nav styles
    .sideNavbar {
        display: none;
    }

    //balance screen
    .balance-wrapper {
        .amountBlockWrapper {
            padding: 8px 10px;
        }
        .balancePill {
            padding: 15px 5px;
            text-align: center;
            span.fontBold {
                display: block;
                margin-left: 0;
            }
        }
        .MuiTabs-root {
            margin: 0 10px;
        }
        .MuiTab-root {
            min-width: unset;
        }
    }
    //transfer tab styles
    .transfer_main {
        padding: 24px 10px;
        .eachTransfer_Section {
            padding: 25px 15px;
        }
        .transferFormWrapper {
            display: block;
            margin-left: 0;
        }
        .transferFormContainer {
            padding: 15px 20px;
        }
        .amountInput {
            display: block;
        }
        .initiate_button {
            margin-top: 20px;
        }
    }

    //reward mobile
    .discount_main_sec {
        padding-right: 0px !important;
    }
    .rewards-wrapper {
        width: 100% !important;
        // costumer aquistion sec
        .rewardsHeadSec {
            padding: 0px 20px;
            .MuiTypography-body1 {
                font-size: 12px !important;
                margin-left: 0px !important;
            }
        }
        .premiumText {
            font-size: 12px;
        }
        .MuiTypography-body1 {
            //font-size: 14px !important;
            font-size: 12px !important;
        }
        .rewardTab_options {
            flex-direction: column !important;
            .reward_option_wid {
                flex-direction: column;
            }
            .MuiFormGroup-root {
                width: 100%;
            }
            .MuiFormControlLabel-root {
                width: 33.3%;
                margin: 0px;
            }
            .no_discount_available {
                font-size: 17px;
                font-family: $plusJakartaSans-Regular;
            }
        }
        .discount_Item {
            width: 48%;
            min-width: 48%;
            min-height: 225px;
            margin: 0px auto;
            height: 225px;
            margin-left: 0px;
            margin-right: 0px;
            p {
                //height: 40px;
                height: 20px;
                //font-size: 15px;
            }
        }
        .discount_Item:nth-child(odd) {
            margin-right: 2%;
            margin-bottom: 25px;
        }
        .discount_Item:nth-child(even) {
            margin-left: 2%;
            margin-bottom: 25px;
        }
        .listItemwrapper {
            flex-direction: row;
            margin-left: 0px !important;
        }
    }

    .discount_imagewrapper {
        min-height: 80px;
        height: 140px;
        //height: 100px;
    }

    .rewards-wrapper
        .rewardTab_options
        .MuiTypography-root.MuiFormControlLabel-label {
        font-size: 15px;
        color: #000;
        letter-spacing: 0.2px;
        font-family: $plusJakartaSans-Regular;
    }
    .rewards-wrapper a.MuiTab-textColorPrimary,
    .rewards-wrapper button.MuiTab-textColorPrimary {
        //width: 40%;
        //margin: 0 5%;
        width: 50%;
        margin: 0 auto;
    }
    .rewards-wrapper .MuiTabs-flexContainer {
        justify-content: left;
    }
    .rewards-wrapper {
        p.MuiTypography-root.MuiTypography-body1 {
            margin-left: 0rem;
            font-size: 20px;
        }
    }
    .reward_option_wid {
        button.MuiButtonBase-root.MuiButton-root.MuiButton-text {
            margin-left: auto;
            margin-top: 15px;
            height: 45px;
            border-radius: 4px;
            width: 120px;
            text-transform: capitalize;
        }
    }
    .PrivateSwitchBase-root-6 {
        padding-left: 0px !important;
    }
    .archived_label {
        padding-left: 1rem;
    }
    .new_discount_ip {
        width: 100% !important;
        display: block !important;
    }
    .new_discount_main {
        width: 100% !important;

        margin-bottom: 0px !important;

        flex-direction: column !important;
    }
    //radio buttons in loyalty
    .radioWrapper {
        width: 100%;
    }
    .select_desc {
        width: 100%;
    }
    .add_discount .select_desc .MuiFormControl-root {
        min-width: 100% !important;
    }
    .discounts_wrapper {
        .select_desc .MuiFormControl-root {
            min-width: 100% !important;
        }
    }
    .uploadfile {
        width: 100% !important;
    }
    label + .MuiInput-formControl {
        margin-top: 16px;
        font-weight: 500;
        font-size: 14px;
        color: #000000f0;
        letter-spacing: 1px;
        margin-bottom: 25px;
    }
    .MuiFormControl-marginNormal {
        margin-top: 0px !important;
    }
    .new_discount_ip {
        .MuiIconButton-root {
            padding-right: 0px !important;
            svg {
                height: 25px;
                width: 25px;
            }
        }
        .MuiInputLabel-formControl {
            font-size: 14px;
            font-family: $plusJakartaSans-Regular !important;
        }
        .MuiFormControl-root {
            width: 100%;
        }
    }
    .add_discount {
        p {
            font-size: 20px !important;
        }
    }
    .table_desktop {
        display: none !important;
    }
    .table_mobile {
        display: block !important;

        .MuiTableContainer-root {
            overflow-x: inherit;
            margin-top: 20px;
        }
        .MuiTable-root {
            //background-color: rgb(244, 249, 255);
            border: 2px solid #D2D2D2;
            border-radius: 3px;
        }
        .MuiTableCell-head {
            color: #0009 !important;
            font-weight: 600 !important;
            line-height: 18px !important;
            font-family: $plusJakartaSans-Regular !important;
            font-size: 0.8rem !important;
        }
        .MuiTableCell-body {
            color: #000;
            font-weight: 600;
            font-size: 14px;
            padding-top: 0px;
        }
        .MuiTableCell-root {
            border-bottom: none;
            vertical-align: top;
        }
        // .MuiTableBody-root {
        //     border: none;

        //     border-bottom: 2px dotted #ccc;
        // }
        .date_table_border_bottom {
            border-bottom: none !important;
        }
    }
    .loyalty_wrapper {
        .lotality_btn_sec {
            button.MuiButtonBase-root.MuiButton-root.MuiButton-text {
                width: 100% !important;
            }
        }
    }
    // .discount_sec {
    //     padding: 0px !important;
    // }
    .new_discount_ip {
        .MuiFormHelperText-root.Mui-error {
            font-size: 13px !important;
            margin-top: -20px;
        }
    }
    .MuiPaper-root.MuiPaper-elevation1 {
        margin-top: 0px !important;
    }

    //balance start
    .amountBlockWrapper {
        display: block !important;
        p {
            display: block !important;
            width: 100%;
            border-top: 1rem solid #fff;
            margin-bottom: 40px;
        }
    }
    .balance_sec {
        .MuiTypography-body1 {
            margin-bottom: 0px !important;
        }
        .date_type {
            width: 48%;
            display: inline-flex !important;
            margin-right: 5px !important;
            .fontBold {
                padding-left: 7px;
                font-size: 13px;
            }
        }
        .time_type {
            margin-right: 0px !important;
            margin-left: 5px !important;
        }
        .balancePill {
            text-align: left !important;
            display: inline-flex !important;
            .current_font {
                margin-left: 50px;
            }
            .fontBold.current_font {
                margin-left: 22px;
            }
        }

        .current_font {
            margin-left: 50px;
        }
        .fontBold.current_font {
            margin-left: 50px;
        }
    }
    .menuNavItems {
        .mobile_side_nav_item {
            // color:#000 !important;
            p {
                font-size: 17px !important;
                letter-spacing: 0.7px;
                margin-left: 20px !important;
            }
        }
    }

    //balance end

    // landing page css
    .max-width {
        padding-left: 20px;
        padding-right: 20px;
        width: auto;
    }

    .landing_footer_sec .footer_sec {
        // padding: 0px 28px;
    }
    .banner_section_home.mob_banner_sec {
        padding-top: 4vh;
        padding-bottom: 4vh;
    }
    .banner_section_home,
    .banner_section {
        padding: 0px;
        height: auto;
        padding-top: 15vh;
        padding-bottom: 10vh;
        .banner_content {
            h2 {
                font-size: 32px;
                line-height: 40px;

                &:nth-last-child(1) {
                    margin-top: 28px;
                    line-height: 51px;
                }
            }
            p {
                max-width: 100%;
                font-size: 20px;
                line-height: 25px;
            }
        }
    }
    .sign_up_sec {
        .sign_up_img {
            width: 100%;
            height: 100%;
        }
        .max-width {
            padding-left: 20px;
        }
        .MuiFormControl-root {
            width: 100%;
        }
        .MuiInputLabel-outlined {
            transform: translate(14px, 16px) scale(1);
            font-weight: 400;
            font-size: 13px;
        }
        input {
            height: 17px;
        }
        h2 {
            font-size: 20px;
            margin-top: 0px;
        }
        .create_btn {
            height: 52px;
            span {
                font-size: 15px;
            }
        }
    }
    .control_sec {
        h2 {
            font-size: 32px;
            line-height: 40px;
            font-family: "PlusJakartaSans-Bold";
            margin-bottom: 28px;
        }
        p {
            width: 100%;
            font-size: 18px;
            line-height: 23px;
        }
    }
    .our_inteligent_sec {
        h2 {
            font-size: 20px;
            line-height: 25px;
            font-family: "PlusJakartaSans-Bold";
            margin-bottom: 28px;
        }
        p {
            width: 100%;
            font-size: 18px;
            line-height: 23px;
        }
    }
    .footer_left_sec {
        width: 100%;
    }
    .landing_footer_sec {
        padding: 30px 12px;
        .footer_sec {
            display: flex;
            flex-wrap: wrap;
            grid-template-columns: initial;
            grid-gap: initial;
        }
        .footer_list {
            padding-top: 20px;
            width: 100%;
            ul {
                padding-left: 0px;
                li {
                    font-family: "PlusJakartaSans-Medium";
                    font-size: 16px;
                    line-height: 18px;
                }
            }
        }
        .footer_left_sec {
            p {
                font-family: "PlusJakartaSans-Medium";
                font-size: 14px;
                line-height: 18px;
                max-width: 100%;
            }
        }

        .copy_right_sec {
            padding: 0px;
            display: block;
            grid-template-columns: inherit;
            grid-gap: initial;
            //text-align: center;
            margin-top: 0px;
            span {
                display: block;
                //text-align: center;
                font-size: 14px;
                margin-top: 20px;
                margin-bottom: 18px;
            }
            a {
                padding-left: 0px;
            }
        }
        .footer_sec {
            .footer_list_sec {
                width: 100%;

                .footer_list_inner {
                    display: grid;
                    grid-template-columns: 65% 35%;
                    width: 100%;
                }
            }
        }
    }
    // .landing_footer_sec .footer_list:nth-last-child(2) {
    //     // padding-left: 60px;

    //     margin-left: auto;
    // }
    .landing_footer_sec .footer_list_inner .footer_list {
        padding-left: 0px;
    }

    .get_the_credit {
        .credit_left {
            padding: 70px 40px;
            p {
                font-size: 18px;
                line-height: 28px;
                margin-top: 25px;
            }
        }
    }
    .payment_right {
        padding: 40px;
        padding-top: 125px;
        padding-bottom: 125px;
        height: auto;
        p {
            font-size: 18px;
            margin-top: 25px;
            line-height: 23px;
            margin-bottom: 0px;
            max-width: 100%;
        }
        h2 {
            // font-weight: 700;
            font-size: 32px;
            line-height: 20px;
            margin-bottom: 10px;
        }
    }
    .network_merchant_sec {
        h2 {
            width: 100%;
            // font-family: "PlusJakartaSans-Bold";
            // font-size: 25px;
            // line-height: 28px;
            // font-family: "PlusJakartaSans-Medium";
            // font-size: 27px;
            // line-height: 33px;
            font-family: "PlusJakartaSans-Bold";
            font-size: 25px;
            font-weight: 700;
            line-height: 28px;
        }
        p {
            font-size: 18px;
            line-height: 23px;
            width: 100%;
            // margin-top: 40px;

            margin-top: 20px;

            font-family: "PlusJakartaSans-Regular";
        }
        .merchants_sub_sec {
            margin-top: 40px;
        }
    }
    .reward_sec {
        h2 {
            font-family: "PlusJakartaSans-Bold";
            font-size: 20px;
            line-height: 28px;
            font-weight: 700;
        }
    }
    .get_the_credit {
        .credit_right {
            h2 {
                font-family: "PlusJakartaSans-Medium";
                font-size: 32px;
                line-height: 40px;
                // text-align: center;
                letter-spacing: -0.02em;
                color: #242f3e;
                padding: 70px;
                margin-bottom: 95px;
                margin-top: 50px;
                p {
                }
            }
        }
    }

    .desktop_none {
        display: block;
    }
    .mobile_none {
        display: none;
    }
    .mobile_none_reg {
        display: none;
    }
    .hr_line {
        opacity: 0.3;
        border: 0.5px solid #ffffff;
        width: 100%;
        margin-top: 24px;
        display: none;
    }
    .playstore_sec {
        img {
            width: 100%;
        }
        h2 {
            font-size: 31px;
            line-height: 44px;
        }
    }

    .mobile_order {
        order: 2;
    }

    .playstore_sec {
        padding-top: 50px;
        height: auto;
        padding-bottom: 45px;
        .playstore_detail_img {
            margin-top: 55px;
        }
    }

    // merchant landing page css start
    .merchant_landing_sec {
        .merchant_banner_sec {
            // height: auto;
            height: 562px;
            img {
                // width: auto;
                width: 100%;
                // height: auto;
                height: 562px;
            }
            .merchant_banner_text {
                // top: 54%;
                h2 {
                    font-size: 32px;
                    line-height: 40px;
                }
                p {
                    font-size: 20px;
                    line-height: 25px;
                }
            }
        }
        .easy_steps_sec {
            background: #f4f9fe;
            padding-top: 0px;
            padding-bottom: 0px;
            .easy_step_left {
                h2 {
                    font-size: 32px;
                    line-height: 40px;
                    padding: 180px 0px;
                    width: 200px;
                    margin: 0px auto;
                }
            }
            .easy_step_right {
                padding: 20px;
                ul {
                    li {
                        font-size: 18px;
                        line-height: 27px;
                    }
                }
                p {
                    font-size: 12px;
                    line-height: 15px;
                    padding-left: 25px;
                }
            }
        }
        .easy_steps_sec,
        .steps_share_wallet {
            background: none;
            .easy_step_right {
                height: auto;
                // padding: 90px 30px;
                // padding: 31px 30px;
                padding: 160px 30px;
                h2 {
                    font-size: 18px;
                    line-height: 23px;
                    font-family: "PlusJakartaSans-Regular";
                    font-weight: 400;
                    img {
                        margin-right: 20px;
                        height: 15px;
                        margin-top: 6px;
                    }
                }
                p {
                    font-size: 16px;
                    line-height: 20px;
                    font-family: "PlusJakartaSans-Regular";
                    font-weight: 400;
                }
            }
        }
        .easy_steps_sec.tailor_sec,
        .steps_share_wallet {
            .easy_step_right h2 {
                font-size: 20px;
                line-height: 25px;
            }
        }
        .technology_sec {
            padding-top: 65px;
            padding-bottom: 100px;
            h2 {
                font-size: 18px;
                line-height: 23px;
                margin-left: 30px;
            }
        }
        .top_features_sec {
            .top_fetures_left {
                padding-left: 30px;
                padding-top: 40px;
                padding-bottom: 70px;
                padding-right: 20px;

                h2 {
                    font-size: 32px;
                    line-height: 40px;
                }
                p {
                    font-size: 18px;
                    line-height: 23px;
                    img {
                        margin-right: 20px;
                        height: 15px;
                        margin-top: 6px;
                    }
                }
            }
            .top_fetures_right.app_highlights {
                img {
                    // width: 100%;
                    // height: auto;
                    width: 100%;
                    object-fit: contain;
                    height: 100%;
                    max-width: 100%;
                }
            }
        }
        .easy_steps_sec.steps_share_wallet {
            .easy_step_left {
                h2 {
                    // width: 270px;
                    width: 278px;
                }
            }
        }
        .ditch_sec {
            padding-bottom: 18px;
            .ditch_card_sec {
                width: 100%;
                padding-top: 15px;
                padding-bottom: 0px;
                h2 {
                    font-size: 18px;
                    line-height: 23px;
                    margin-top: 30px;
                }
                p {
                    font-size: 18px;
                    line-height: 23px;
                }
            }
            .ditch_right_sec {
                margin-top: 50px;
                h2 {
                    font-size: 32px;
                    line-height: 40px;
                    max-width: 270px;
                    /* padding-left: 20px; */
                    margin: 0px auto;
                    padding-bottom: 40px;
                    padding-right: 50px;
                    padding-left: 50px;
                }
            }
            .ditch_left_sec {
                padding-top: 10px;
                padding-left: 0px;
            }
        }
        .steps_wallet_sec {
            .steps_wallet_banner {
                height: auto;
                margin-top: 50px;
                .steps_wallet_banner_text {
                    position: inherit;
                    top: initial;
                    left: initial;
                    padding: 20px;
                    margin-top: 30px;
                    transform: initial;
                    h2 {
                        &:nth-child(1) {
                            font-size: 31px;
                            line-height: 39px;
                        }
                        &:nth-child(2) {
                            margin-top: 50px;
                            font-size: 21px;
                            line-height: 30px;
                        }
                        &:nth-child(3) {
                            font-size: 21px;
                            line-height: 30px;
                        }
                        &:nth-child(4) {
                            font-size: 21px;
                            line-height: 30px;
                        }
                    }
                    .steps_wallet_sub_sec {
                        width: 100%;
                        p {
                            font-size: 18px;
                            line-height: 23px;
                        }
                    }
                }
            }
        }
        .easy_steps_sec.tailor_sec {
            .easy_step_left.tailor_inner_sec {
                h2 {
                    font-size: 30px;
                    line-height: 40px;
                    max-width: 300px;
                    margin: 0px auto;
                    width: initial;
                }
            }
            .easy_step_right {
                // padding: 150px 26px;
                padding: 115px 26px;
                h2 {
                    margin-top: 20px;
                }
            }
        }
    }
    .easy_steps_sec.steps_share_wallet .easy_step_left {
        height: 562px;
    }
    .ul_circle_sec {
        margin-top: 30px;
        ul {
            padding-right: 10px;
            li {
                font-size: 18px;
                line-height: 30px;
                margin-top: 18px;
                br {
                    display: none;
                }
                &::after {
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }
    .merchant_break_sec {
        background: linear-gradient(180deg, #4e2b5e 0%, #4d2a5c 100%);
        margin-top: 60px;

        .merchant_break_banner {
            position: relative;

            .merchant_break_banner_text {
                position: absolute;
                top: initial;
                left: initial;
                bottom: -5%;
                transform: initial;
                width: 100%;
                padding-bottom: 60px;
                padding-top: 60px;
                h2 {
                    font-size: 32px;
                    line-height: 40px;
                    padding: 0px 20px;
                    br {
                        display: none;
                    }
                }
            }
        }
    }

    // merchant landing page css end
    // lenders landing page css start
    .lenders_landing_sec {
        .lenders_break_sec {
            .lenders_break_banner {
                img {
                    height: auto;
                }
                .lenders_break_banner_text {
                    position: initial;
                    top: initial;
                    left: initial;
                    transform: initial;
                    width: 90%;
                    padding: 20px 20px;
                    h2 {
                        font-size: 20px;
                        line-height: 25px;
                        // font-family: "PlusJakartaSans-Medium";
                        // font-weight: 500;

                        font-family: "PlusJakartaSans-Bold";
                        font-weight: 700;
                    }
                    p {
                        font-size: 18px;
                        line-height: 23px;
                    }
                }
            }
        }
        .expected_rate_sec {
            background: #f4f9fe;
            padding-top: 100px;
            padding-bottom: 90px;
            .expected_rate_text {
                h2 {
                    font-size: 20px;
                    line-height: 25px;
                }
                h3 {
                    font-size: 20px;
                    line-height: 25px;
                    margin-top: 25px;
                }
                p {
                    font-size: 18px;
                    line-height: 23px;
                }
            }
        }
        .borrower_characteristics_sec {
            .borrower_text_sec {
                width: 100%;
                h2 {
                    font-size: 19px;
                    line-height: 25px;
                }
                p {
                    font-size: 18px;
                    line-height: 23px;
                }
            }
        }
        .loan_features_sec {
            padding-top: 55px;
            padding-bottom: 55px;
            p {
                font-size: 18px;
                line-height: 23px;
            }
            h2 {
                font-size: 20px;
                line-height: 25px;
            }
        }
        .embedded_sec {
            .embedded_right {
                background: #f4f9fe;
                padding: 40px 35px;
                height: auto;
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .embedded_left {
                background: #242f3e;
                padding: 159px 45px;
                h2 {
                    font-size: 20px;
                    line-height: 25px;
                    font-weight: 400;
                }
            }
        }
        .transparency_sec {
            .transparency_left {
                h2 {
                    font-size: 20px;
                    line-height: 25px;
                }
            }
            .transparency_right {
                // padding: 144px 45px;
                padding: 115px 45px;
                h2 {
                    font-size: 20px;
                    line-height: 25px;
                    margin-bottom: 40px;
                    font-family: "PlusJakartaSans-Regular";
                    font-weight: 400;
                }
            }
        }
        .high_quality_loan_sec {
            .high_quality_title {
                font-size: 32px;
                line-height: 40px;
                text-align: center;
                margin-bottom: 0px;
                font-family: "PlusJakartaSans-Bold";
                font-weight: 700;
            }
            .high_quality_loan_text {
                h2 {
                    font-size: 20px;
                    line-height: 25px;
                    font-family: "PlusJakartaSans-Bold";
                    font-weight: 700;
                }
                .high_quality_info_data {
                    p {
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
            }
        }
        .market_condition_sec {
            .market_condition_left {
                padding: 144px 40px;
                h2 {
                    font-size: 20px;
                    line-height: 28px;
                    font-weight: 400;
                }
                .rate_return_sec {
                    p {
                        font-size: 20px;
                        line-height: 28px;
                        font-family: "PlusJakartaSans-Regular";
                        font-weight: 400;
                        img {
                            margin-right: 12px;
                            height: 18px;
                        }
                    }
                }
            }
        }
        .market_condition_sec {
            .market_condition_right {
                padding: 60px 10px;
                height: auto;
                h2 {
                    font-size: 20px;
                    line-height: 28px;
                    padding-left: 20px;
                    padding-right: 20px;
                    margin-left: 0px;
                }
                .bloomburg_date_sec {
                    // width: auto;
                    width: 310px;
                    padding-left: 20px;
                    padding-right: 20px;
                    p {
                        // font-size: 16px;
                    }
                }
            }
        }
        .table_detail_sec {
            width: 100%;
            padding: 18px 20px;
            .table_container_sec {
                table {
                    .MuiTableCell-root.MuiTableCell-body,
                    .MuiTableCell-root.MuiTableCell-head {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
        }
        .customer_facing_sec {
            width: 100%;

            h2 {
                font-size: 20px;
                line-height: 25px;
                width: 100%;
            }
            p {
                font-size: 18px;
                line-height: 23px;
                margin-top: 28px;
            }
        }
    }
    .lenders_landing_sec {
        .high_quality_loan_sec {
            .loan_sec {
                padding-top: 0px;
                width: 80%;
                margin: 0px auto;
            }
            .high_quality_loan_text {
                padding-right: 0px;
                margin-top: 80px;
                .high_quality_info_data {
                    min-height: 300px;
                    // min-height: auto;
                    padding: 20px 15px;
                }
            }
        }
        .transparency_sec {
            .transparency_left {
                // max-width: 229px;
                max-width: 240px;
                width: 100%;
                padding: 100px 30px;
                height: auto;
            }
        }
        .high_quality_loan_sec.loan_fetures_sec {
            .high_quality_loan_text {
                .high_quality_info_data {
                    min-height: 220px;
                }
            }
        }
    }

    // lenders landing page css end
    // modal code start
    .modal_sec {
        width: 100%;
        margin-top: 0px;
        .close_icon {
            right: 30px;
        }
        .modal_form_list {
            width: 90%;
        }
    }
    // modal code end
    .merchant_landing_sec {
        .heading_tag_line p {
            // margin-left: -45px;
        }
    }
    .heading_tag_line p {
        font-size: 20px;
        line-height: 25px;
        margin-top: 0px;
    }
    .unplank_logo {
        width: 124px;
    }
    .control_sec {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .banner_image {
        .heading_tag_line {
            margin-top: 20px;
        }
    }
    .payment_left img {
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .header_max_width {
        min-width: 100%;
        max-width: 100%;
        padding-left: 0px;
        padding-right: 0px;
        margin: auto;
    }
    .sign_up_sec {
        .MuiInputBase-formControl,
        .create_btn {
            max-width: 100%;
        }
    }
    .success_msg_sec {
        padding: 75px 24px;
        width:83%;
        p {
            font-size: 18px;
            line-height: 25px;
            text-align: center;
        }
        .modal_close_icon {
            right: 20px;
        }
    }
    .privacy_heading {
        h2 {
            font-size: 35px;
        }
    }
    .headerPadding {
        .max-width {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
    // faq code start

    .faq_detail_sec {
        padding-top: 15px;
        .faq_papper_sec {
            padding: 30px 10px;
            padding-top: 10px;
            margin-top: 0px;
        }
        .faq_accordion_sec .MuiAccordionSummary-content.Mui-expanded {
            margin: 12px 0;
        }
    }
    // faq code end
}

.reward_option_wid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

//reward 320px media start
@media (max-width: 359px) {
    //banner sec
    .homeBannerContent {
        width: 150px;
    }
    .createAccountSec {
        padding: 24px 50px 10px;
    }
    //add store header text
    .forHeaderText {
        margin: 0px auto;
        .MuiTypography-body1 {
            font-size: 14px;
        }
    }
    .storeAddress {
        .MuiFormLabel-root {
            font-size: 12px;
            font-family: $plusJakartaSans-Regular;
        }
    }
    .storeRadioGroup {
        .MuiTypography-body1 {
            font-family: $plusJakartaSans-Regular;
            font-size: 12px !important;
        }
    }
    .dragTxt {
        display: none;
    }
    .actionBtn {
        justify-content: space-around;
    }
    .registerFormTxt{
        .MuiFormLabel-root {
            font-size: 12px;
        }
    }
    //discounts radio sec
    .radioWrapper {
        .MuiFormControlLabel-root {
            &:nth-last-child(1) {
                margin-left: 0px;
                margin-right: auto;
            }
        }
    }
    //tabs
    .MuiTab-root {
        padding: 6px 6px !important;
    }
    //file upload in discounts
    .fileUploadWrapper {
        width: 250px;
    }
    .MuiTab-wrapper {
        font-size: 12px !important;
    }
    .PrivateSwitchBase-root-2 {
        padding: 4px;
    }
    .rewards-wrapper
        .rewardTab_options
        .MuiTypography-root.MuiFormControlLabel-label {
        font-size: 12px !important;
    }
    .reward_option_wid {
        .PrivateSwitchBase-root-6 {
            padding: 4px;
        }
    }
    .rewards-wrapper .discount_Item {
        min-height: 225px;
        height: 225px;
        width: 52% !important;
        min-width: 52% !important;
    }
    .discount_Item {
        p {
            font-size: 15px;
            height: 40px;
            overflow: hidden;
        }
    }
    .discount_main_sec {
        .rewardTab_options {
            .no_discount_available {
                font-size: 14px;
                font-family: $plusJakartaSans-Regular;
            }
        }
    }
    .amountBlockWrapper {
        .date_type .fontBold {
            font-size: 10px;
            padding-top: 4px;
        }

        .fontBold.current_font {
            font-size: 10px;
        }
    }

    .table_mobile .MuiTableCell-body {
        font-size: 10px;
    }
    .table_mobile .MuiTableCell-head {
        line-height: 16px !important;

        font-size: 0.7rem !important;
    }
}
//reward 320px media end

// merchant signup start
.dob_input {
    .MuiFormControl-marginNormal {
        width: 100%;
        margin-top: 14px;
    }
}

@media (min-width: 300px) and (max-width: 370px) {
    .signupWrapper {
        .MuiInputLabel-root,
        .MuiInputBase-input {
            font-size: 13px;
        }
    }
    .imagePreviewWrapper {
        margin-top: -210px;
    }
    .storeimagePreviewWrapper {
        margin-top: -210px;
        margin-left: 40px;
    }
    .otpSec {
        width: 100%;
    }
    .otpSec {
        .MuiFormControl-root {
            width: 30px !important;
        }
    }
}

@media (min-width: 300px) and (max-width: 400px) {
    .deleteBtn {
        margin-left: 5px;
    }
 }

@media (min-width: 300px) and (max-width: 767px) {
    // side menu start
    // .menuNavItems {
    //     .mobile_side_nav_item {
    //         &:focus {
    //             background-color: #e8f1fe !important;
    //             border-left: 5px solid #1a7cfa !important;
    //             outline: none;
    //         }
    //         &:hover {
    //             background-color: #e8f1fe !important;
    //             border-left: 5px solid #1a7cfa !important;
    //             outline: none;
    //         }
    //     }
    // }
    // side menu end
}
@media (min-width: 300px) and (max-width: 767px) {
    .dob_input {
        margin: 13px auto !important;
    }
}
@media (min-width: 768px) and (max-width: 1023px) {
    //new home screen 
    .homeMain {
        overflow-x: hidden;
    }
    .app_to_app_sec {
        .app_to_app_content {
            margin-left: 30px;
        }
        img {
            height: auto;
            width: 85%;
            margin-top: 50px;
            margin-left: 60px;
        }
        .grid_top {
            margin-top: 30px;
        }
        button {
            width: 100%;
        }
    }
    .payment_method_sec {
        flex-direction: column-reverse;
        img {
            width: 85%;
            margin: auto;
        }
    }
    .payment_method_content {
        width: auto;
    }
    .nochangeSec {
        padding-left: 50px;
    }
    .chat_with_cust_sec {
        flex-direction: column-reverse;
    }
    .steps_sec {
        flex-direction: column;
        img{
            width: auto;
        }
        .five_easy_steps {
            width: 100%;
        }
    }
    .data_privacy_sec {
        flex-direction: column-reverse;
        img {
            height: auto;
            width: 85%;
            margin: auto;
        }
    }
    .gradient_sec {
        h2 {
            font-size: 38px;
            line-height: 48px;
        }
        .gradient_content {
            padding: 20px 0px;
        }
    }
    .attract_img {
        height: auto;
        width: 85%;
        margin: auto;
    }
    .noChangeImg {
        img {
            width: 85%;
            margin: auto;
        }
    }
    .attract_last_img {
        height: auto;
        width: 85%;
        margin-left: 50px;
    }
    //shop with unnplank
    .shopUnplank_banner_sec {
        height: auto;
        img {
            height: auto;
        }
    }
    .transact_ease_sec {
        flex-direction: column;
        gap: 0px;
    }
    .transact_ease_content {
        padding-top: 1em;
    }
    .credit_content {
        padding-top: 12vh;
        padding-right: 6vh;
        padding-bottom: 6vh;
    }
    //banner sec
    .homeBannerContent {
        width: 150px;
    }
    //store pop up screens
    .storePopUps {
        .MuiDialog-paperWidthSm {
            width: 100%;
            max-width: 400px;
        }
    }
    //imagepreview sec
    .image_preview_wrapper {
        margin-top: 10px;
    }
    //fileupload sec
    .fileUploadSec {
        flex-direction: column;
    }
    .fileUploadWrapper {
        width: 350px;
    }
    .changeSec {
        gap: 3rem;
    }
    //discounts grp
    .promotionWrapper {
        width: 92%;
    }
    .discounts_wrapper {
        width: 80%;
    }
    .radioWrapper {
        width: 80%;
    }
    //register account
    .mobile_none_reg {
        display: none;
    }
    //decline and verify user
    .cardContainer {
        width: 80%;
    }
    .decImgWidth {
        width: 400px;
    }
    //header styles
    .webHeader {
        // display: none;
    }
    .mobileHeader {
        // display: block;
        .menuOverlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            width: 350px;
            background-color: $jumna-blue;
            // &::after {
            //     background: rgba(255, 255, 255, 0.4);
            //     position: fixed;
            //     content: "";
            //     top: 0;
            //     left: 0;
            //     right: 0;
            //     bottom: 0;
            //     z-index: -1;
            // }
        }
        .menuNavItems {
            padding: 20px 10px;
        }
        .mobileMenuItem {
            margin: 30px 20px;
            color: $white;
            font-weight: 500;
            font-size: 18px;
            line-height: 23px;
            font-family: "PlusJakartaSans-Regular";
            a {
                color: $white;
                font-weight: 500;
                font-size: 18px;
                line-height: 23px;
                font-family: "PlusJakartaSans-Regular";
                text-decoration: none;
            }
        }
    }
}
.dob_input {
    button {
        padding: 3px;
    }
}
// merchant signup end

// reward sec start
.reward_wid_sec {
    width: 92%;
    margin: 0 auto 30px;
}
// reward sec end
.admin_wid_sec {
    width: 92%;
    margin: 20px auto;
}
// balance sec start
.balance_wid_sec {
    width: 92%;
    margin: auto;
}
// balance sec end
// signup sec start
.sign_up_logo {
    width: 65px;
}
.sign_up_next_sec {
    margin-top: 30px;
    width: 100%;
    text-align: right;
}
.signup_wid_sec {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 85px;
    padding-bottom: 40px;
}
.merchent_wid_sec {
    width: 70%;
    align-items: center;
    padding: 30px;
    font-size: 14px;
}
.merchent_signup_ip {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
// signup sec end

@media screen and(max-width:390px) {
    .rewards-wrapper {
        width: 100% !important;
        .rewardTab_options {
            flex-direction: column !important;
            .MuiFormControlLabel-root {
                width: 33.3%;
                //margin: 1px;
                margin: 0px;
            }
        }
    }
}
@media (min-width: 768px) and (max-width: 992px) {
    //header styles
    .headerRight .MuiButton-label {
        font-size: 12px;
    }
    .headerLeft p { 
        font-size: 11px;
        a{
            font-size: 11px;
        }
     }
    .unplank_logo {
        width: 155px;
    }
    .header_max_width {
        min-width: 650px;
        max-width: 650px;
        width: 100%;
        margin: 0px;
    }
    .banner_section_home,
    .banner_section {
        height: auto;
        .banner_content {
            p {
                font-size: 34px;
                line-height: 48px;
                max-width: 100%;
            }
            h2 {
                // font-size: 52px;
                // line-height: 68px;

                font-size: 55px;
                line-height: 75px;
            }
        }
    }
    .playstore_sec {
        img {
            width: 100%;
            margin: 0px auto;
        }
        h2 {
            font-size: 52px;
            line-height: 70px;
        }
    }
    .sign_up_sec {
        .max-width {
            padding-left: 40px;
        }
        .MuiInputBase-formControl,
        .create_btn {
            max-width: 100%;
        }
        .sign_up_img {
            width: 100%;
            height: auto;
            margin-top: 43px;
        }
    }
    .merchant_landing_sec {
        .merchant_banner_sec {
            height: 85vh;
            img {
                height: 85vh;
            }
            .merchant_banner_text {
                h2 {
                    // font-size: 52px;
                    // line-height: 70px;
                    font-size: 38px;
                    line-height: 53px;
                }
            }
        }
        .easy_steps_sec {
            .easy_step_left {
                height: 500px;

                padding: 100px 0px;
            }
        }
        .technology_sec {
            h2 {
                font-size: 35px;
                line-height: 49px;
            }
        }
    }
    .payment_right p {
        max-width: 100%;
    }
    .control_sec p {
        width: 100%;
    }
    .top_features_sec .top_fetures_right img {
        margin-top: 70px;
    }
    .top_features_sec .top_fetures_left {
        margin-top: 70px;
    }
    .merchant_break_sec {
        margin-top: 70px;
        br {
            display: none;
        }
    }
    .reward_sec h2 {
        width: 100%;
    }
    .lenders_landing_sec {
        .embedded_sec {
            .embedded_right {
                margin-top: 70px;
            }
        }
        .transparency_sec {
            .transparency_left,
            .transparency_right {
                margin-top: 70px;
            }
        }
        .high_quality_loan_sec {
            .high_quality_loan_text {
                .high_quality_info_data {
                    min-height: 300px;
                }
            }
        }
        .customer_facing_sec h2 {
            width: 100%;
        }
    }
    .landing_footer_sec {
        .footer_sec {
            display: block;
            grid-template-columns: 100%;
            .footer_list_sec {
                .footer_list_inner {
                    display: grid;
                    grid-template-columns: 70% 30%;
                }
            }
        }
        .footer_left_sec {
            p {
                max-width: 100%;
            }
        }
        // .copy_right_sec {
        //     text-align: center;
        // }
    }
    .headerRight {
        .MuiButton-outlined,
        .MuiButton-contained {
            width: 84px;
        }
    }
    .headerLeft {
        margin-right: 8px;
        p {
            margin: 0px 6px;
        }
    }
    .playstore_sec {
        height: auto;
        padding-bottom: 45px;
    }
}
@media (min-width: 992px) and (max-width: 1025px) {
    .header_max_width {
        min-width: 920px;
        max-width: 920px;
    }
    .playstore_sec img {
        width: 100%;
    }
    .sign_up_right {
        padding: 0px 20px;
        margin-top: 69px;
        .sign_up_img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .top_features_sec {
        .top_fetures_right {
            img {
                width: 100%;
                height: 635px;
                object-fit: cover;
            }
        }
        .top_fetures_left {
            h2 {
                margin-top: 0px;
            }
        }
    }
    .merchant_break_sec {
        .merchant_break_banner {
            .merchant_break_banner_text {
                left: 60px;
                width: 470px;
                transform: translateY(-50%);
            }
        }
    }
    .ditch_sec .ditch_inner_sec .ditch_right_sec h2 {
        padding-left: 0px;
    }
    .easy_steps_sec.tailor_sec .easy_step_right h2 {
        font-size: 30px;
        line-height: 40px;
    }
    .heading_tag_line.heading_title_line {
        .max-width {
            padding-left: 20px;
        }
    }
    .banner_section .banner_content h2 {
        font-size: 72px;
    }
    .banner_section_home .banner_content h2 {
        font-size: 72px;
    }
    .merchant_banner_sec {
        .merchant_banner_text {
            h2 {
                br {
                    display: none;
                }
            }
        }
    }
    .top_features_sec .top_fetures_left {
        padding-right: 80px;
    }
    .merchant_landing_sec {
        .easy_steps_sec {
            .easy_step_right {
                height: 620px;
                padding: 80px 100px;
                padding-bottom: 180px;
                h2 {
                    font-size: 28px;
                    line-height: 40px;
                }
            }
        }
        .transparency_sec .transparency_right {
            height: 567px;
            padding: 100px;
        }
        .easy_steps_sec.steps_share_wallet .easy_step_right {
            height: 567px;
            padding-bottom: 100px;
        }
    }

    .easy_steps_sec.tailor_sec .easy_step_right {
        height: 567px;
        padding-bottom: 100px;
    }
    .lenders_landing_sec {
        .high_quality_loan_sec {
            .high_quality_loan_text {
                h2 {
                    font-size: 24px;
                }
                .high_quality_info_data {
                    // min-height: 705px;
                    min-height: 750px;
                }
            }
        }
        .loan_fetures_sec {
            .high_quality_loan_text {
                .high_quality_info_data {
                    min-height: 535px;
                }
            }
        }
        .market_condition_sec {
            .market_condition_left {
                padding: 100px;
                height: 567px;
                h2 {
                    font-size: 28px;
                    line-height: 40px;
                }
            }
            .market_condition_right {
                h2 {
                    font-size: 28px;
                    line-height: 40px;

                    padding-left: 0px;
                    padding-right: 15px;
                }
                .bloomburg_date_sec {
                    width: 340px;
                }
            }
        }
        .embedded_sec {
            .embedded_left {
                padding: 100px;
                height: 567px;

                h2 {
                    font-size: 28px;
                }
            }
        }
        .transparency_sec {
            .transparency_right {
                padding: 100px;
                height: 567px;
                h2 {
                    font-size: 28px;
                    line-height: 40px;
                }
            }
            .transparency_left {
                h2 {
                    font-size: 28px;
                    line-height: 40px;
                    padding-left: 40px;
                    padding-right: 40px;
                    br {
                        display: none;
                    }
                }
            }
        }
    }
    .get_the_credit .credit_left {
        padding: 31px 70px;
        height: 705px;
        p {
            font-size: 28px;
            line-height: 40px;
        }
    }
    .payment_right p {
        font-size: 25px;
        line-height: 35px;
    }
    .landing_footer_sec {
        .copy_right_sec span {
            font-size: 16px;
        }
    }
    .banner_section {
        height: 95vh;
    }
    .merchant_landing_sec {
        .merchant_banner_sec {
            .merchant_banner_text {
                padding-top: 23vh;
                h2 {
                    font-size: 72px;
                }
            }
        }
    }
    .playstore_sec {
        height: auto;
        padding-bottom: 45px;
    }
}
@media (min-width: 860px) and (max-width: 1300px) {
    // .otpSec {
    //     width: 61%;
    // }
}
@media (min-width: 1024px) and (max-width: 1180px) {
    //home screen UI
    .app_to_app_sec {
        .grid_top {
            margin-top: 90px;
        }
        .app_to_app_content {
            h2{
                font-size: 30px;
                line-height: 40px;
            }
            p{
                font-size: 18px;
                line-height: 25px;
            }
        }
        img {
            height: 66vh;
            width: 100%;
            margin-top: 30px;
        }
    }
    .payment_sec {
        padding-top: 60px;
    }
    .business_content {
        .tick_sec {
            padding-top: 20px;
        }
        .tick_content {
            span{
                font-size: 20px;
                line-height: 30px;
            }
        }
        p {
            font-size: 16px;
            line-height: 25px;
        }
        .tick_spacing {
            padding-bottom: 10px;
        }
    }
    .steps_sec {
        img {
            width: 70%;
        }
        .five_easy_steps {
            width: 30%;
        }
    }
    .five_easy_steps {
        padding: 50px 20px;
        h2 {
            font-size: 30px;
        }
        p {
            font-size: 20px;
            line-height: 30px;
        }
        .setUpText {
            margin-bottom: 10px;
            span {
                font-size: 20px;
                line-height: 30px;
            }
        }
    }
    .data_privacy_sec {
        gap: 1rem;
        img {
            width: 100%;
            height: 60vh;
        }
        .data_privacy_text {
            h2 {
                font-size: 32px;
                line-height: 33px;
            }
            p {
                font-size: 18px;
                line-height: 25px;
            }
        }
    }
    .attract_last_img {
        height: auto;
        width: 90%;
    }
    .know_more_sec {
        padding: 0px 50px;
        .attract_sub_text {
            font-size: 21px;
            line-height: 25px;
            padding-top: 10px;
        }
        h2 {
            font-size: 32px;
            line-height: 40px;
        }
        p {
            font-size: 18px;
            line-height: 25px;
        }
        .MuiButton-contained:hover,
        .MuiButton-contained{
            background-color: $jumna-blue;
            color: $white;
            width: 100%;
        }
    }
    .payment_method_sec {
        img {
            width: 100%;
            height: 70vh;
        }
    }
    .payment_method_content {
        width: auto;
        margin: auto;
        padding: 0px 50px 56px
        h2 {
            font-size: 30px;
            line-height: 40px;
        }
        p,h4 {
            font-size: 20px;
            line-height: 25px;
        }
    }
    .chat_with_cust_sec {
        .chat_text {
            h2 {
                font-size: 30px;
                line-height: 40px;
            }
            h4 {
                font-size: 25px;
                line-height: 30px;
                margin: 10px 0px;
            }
            p {
                font-size: 20px;
                line-height: 25px;
            }
        }
    }
    .attract_img {
        width: 50%;
        height: 500px;
    }
    .noChangeImg {
        img {
            width: 100%;
        }
    }
    //shop with unplank 
    .shopUnplank_banner_sec {
        height: auto;
        .shopUnplank_banner_text {
            padding-top: 30px;
        }
    }
    .credit_content {
        padding-right: 4vh;
        h2 {
            font-size: 28px;
            line-height: 38px;
        }
        p {
            font-size: 18px;
        }
    }
    .credit_type_sec {
        padding: 30px;
        p{
            font-size: 18px;
            line-height: 33px;
            margin-block-start: 20px;
            margin-block-end: 20px;
        }
    }
    .transact_ease_sec {
        gap: 30px;
        img {
            width: 50%;
            height: 410px;
        }
    }
    .transact_ease_content {
        padding-top: 1em;
        h2 {
            font-size: 28px;
            line-height: 38px;
        }
        p {
            font-size: 18px;
            line-height: 25px;
        }
    }
    //imagepreview sec
    .image_preview_wrapper {
        margin-top: 10px;
    }
    //fileupload sec
    .fileUploadSec {
        flex-direction: column;
    }
    .fileUploadWrapper {
        width: 350px;
    }
    .changeSec {
        gap: 3rem;
    }
    //register account
    .mobile_none_reg {
        display: none;
    }
}
@media (min-width: 1025px) and (max-width: 1300px) {
    .top_features_sec .top_fetures_left {
        padding-left: 63px;
        padding-right: 20px;
    }
    .merchant_break_sec {
        .merchant_break_banner {
            .merchant_break_banner_text {
                left: 30.8%;
            }
        }
    }
    .header_max_width {
        min-width: 1180px;
    }

    .banner_section .banner_content h2 {
        font-size: 63px;
        line-height: 76px;
    }
    .banner_section_home .banner_content h2 {
        font-size: 63px;
        line-height: 76px;
    }
    .lenders_landing_sec {
        .high_quality_loan_sec {
            .high_quality_loan_text {
                .high_quality_info_data {
                    min-height: 500px;
                }
            }
        }
        .loan_fetures_sec {
            .high_quality_loan_text {
                .high_quality_info_data {
                    min-height: 375px;
                }
            }
        }
    }
    .lenders_landing_sec
        .market_condition_sec
        .market_condition_right
        .bloomburg_date_sec {
        width: 445px;
    }
    .ditch_sec .ditch_inner_sec .ditch_right_sec h2 {
        padding-left: 0px;
    }
    .sign_up_sec {
        .sign_up_img {
            width: 100%;
            height: 500px;
            object-fit: cover;
        }
    }
    .playstore_sec {
        height: auto;
        padding-bottom: 45px;
    }
}

@media (min-width: 1301px) and (max-width: 1440px) {
    .header_max_width {
        min-width: 1315px;
    }

    .top_features_sec .top_fetures_left {
        padding-left: 66px;
    }
    .merchant_break_sec .merchant_break_banner .merchant_break_banner_text {
        left: 60px;
        width: 750px;
        transform: translateY(-50%);
    }
    .lenders_landing_sec
        .market_condition_sec
        .market_condition_right
        .bloomburg_date_sec {
        width: 480px;
    }
    .sign_up_sec .sign_up_img {
        height: 545px;
    }
}

@media (min-width: 1350px) and (max-width: 1400px) {
    .header_max_width {
        min-width: 1272px;
    }
    .banner_section .banner_content h2 {
        font-size: 68px;
    }
    .banner_section_home .banner_content h2 {
        font-size: 68px;
    }
    .ditch_sec .ditch_inner_sec .ditch_right_sec h2 {
        padding-left: 0px;
    }
}

@media (min-width: 1360px) and (max-width: 1370px) {
    .header_max_width {
        min-width: 1250px;
    }
}
@media (min-width: 1500px) {
    // .otpSec {
    //     width: 55%;
    // }
    .max-width {
        padding-left: 40px;
        padding-right: 40px;
    }
    .header_max_width {
        padding-left: 40px;
        padding-right: 40px;
    }
}
@media (min-width: 1799px) {
    .merchant_break_sec .merchant_break_banner .merchant_break_banner_text {
        left: 215px;
        width: 750px;
        transform: translateY(-50%);
    }
}
@media (min-width: 1900px) {
    .merchant_break_sec .merchant_break_banner .merchant_break_banner_text {
        left: 272px;
        width: 750px;
        transform: translateY(-50%);
    }
}


.paginationSec {
    display: flex;
    justify-content: center;
    margin: 20px;
}


.home-page-email-error{
    color: red;
}

// firefox
@supports (-moz-appearance: none) {
input[type='password'] {
        font-family: 'Plus Jakarta Sans', sans-serif;

    }
}
// safari
.webkit input[type='password'] {
    font-family: 'Plus Jakarta Sans', sans-serif;
}
@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */ 
    input[type='password'] {
        font-family: 'Plus Jakarta Sans', sans-serif;
    }
}
